import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;

  gap: 6em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-top: 4em;
  position: relative;

  @media (max-width: 1000px) {
    gap: 3em;
  }

  @media (max-width: 790px) {
    gap: 1.5em;
    margin-top: 1em;
    grid-template-columns: 1fr;
  }
`;

const ParallaxBoxes = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  justify-content: space-evenly;

  /* Parallax rectangles */

  & > div {
    width: 6.5%;
    height: 80%;
    z-index: -1;
    position: absolute;
    transition: transform 0.2s ease;

    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 750px) {
      display: none;
    }

    &:nth-of-type(1) {
      top: 5em;
      left: 22%;
      background-color: var(--ked-orange);
    }

    &:nth-of-type(2) {
      top: -1em;
      left: 59%;
      background-color: var(--ked-red);
    }

    &:nth-of-type(3) {
      top: 5em;
      left: 97%;
      background-color: var(--ked-blue);
    }
  }
`;

const ProductsSection = (props) => {
  const { children, ...rest } = props;
  return (
    <Wrapper {...rest}>
      <ParallaxBoxes>
        <div data-parallax="-0.3" data-parallax-mode="absolute" />
        <div data-parallax="0.15" data-parallax-mode="absolute" />
        <div data-parallax="-0.2" data-parallax-mode="absolute" />
      </ParallaxBoxes>
      {children}
    </Wrapper>
  );
};

ProductsSection.displayName = "ProductsSection";
ProductsSection.propTypes = {
  children: PropTypes.node,
};

ProductsSection.defaultProps = {};

export default ProductsSection;
