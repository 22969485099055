import React from "react";
import styled from "styled-components";

const HtmlWrapper = styled.div`
  ${({ textColor }) => textColor && `color: ${textColor};`}

  & a {
    z-index: 0;
    color: inherit;
    position: relative;
    text-decoration: none !important;

    transition: background-position 0.15s ease-in-out, padding 0.15s ease-in-out;
    background-size: 100% 200%;
    background-position: 0 0;
    word-break: break-word;

    ${({ akcentColor = "var( --ked-text-gray)" }) => `

      background-image: linear-gradient(
        transparent 0%,
        transparent calc(50% - 2px),
        ${akcentColor} calc(50% - 2px),
        ${akcentColor} 100%
      );

      &:hover {
        background-image: linear-gradient(
          transparent 0%,
          transparent calc(50% - 2px),
          ${akcentColor} calc(50% - 2px),
          ${akcentColor} 100%
        );
        background-position: 0 100%;
      }
    `};
  }

  & hr {
    border: none;
    border-bottom: 1px solid var(--ked-gray);
  }

  & p {
    margin: 0.5em 0;
    line-height: inherit;
  }

  & strong {
    font-weight: 600;
  }

  & img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 1em auto;
  }

  & blockquote {
    margin: 1.5em 0;
    text-align: left;

    & ul {
      list-style: none;
      padding: 0;
    }
  }

  & ul {
    padding: 0;
    padding-left: 2em;
    list-style: disc;

    & > li {
      margin: 0.3em 0;
    }
  }
`;

export default function Html(props) {
  const { children, ...rest } = props;
  return (
    <HtmlWrapper {...rest} dangerouslySetInnerHTML={{ __html: children }} />
  );
}
