import React from "react";
import styled from "styled-components";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container, Stack, Rail, Grid } from "@ludekarts/base-ui/layout";

// Components.
import Html from "components/html";
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import PartnerCard from "components/cards/partner";
import { TShape } from "components/tetris";

const Wrapper = styled.div`
  gap: 4em;
  display: grid;
  padding-top: 2em;
  padding-bottom: 3em;
  grid-template-columns: 1fr;
`;

const Win = styled.div`
  /* position: absolute; */
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5em;

  /* left: 2em; */
  /* top: 1.5em; */

  & > svg {
    margin-bottom: 0.3em;
  }
`;

const Content = styled.section`
  display: flex;
  position: relative;
  background-color: var(--ked-lite-blue);

  & .ked-html {
    color: white;
    padding: 2em 0;
  }

  @media (max-width: 630px) {
    flex-direction: column;
    & .ked-html {
      padding: 0 2em 2em;
    }
  }
`;

const Star = ({ size, color, ...props }) => (
  <svg width={size} viewBox="0 0 64 60" fill={color} {...props}>
    <polygon
      points="32,0 41.888,19.75 64,22.918 48,38.292 51.776,60 32,49.75 12.223,60 16,38.292 0,22.918
	22.112,19.75 "
    />
  </svg>
);

const Acknowledgments = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [acknowledgments] = useApi(api.getAcknowledgmentsContent, lang);

  return (
    <FadeIn trigger={!!acknowledgments}>
      {acknowledgments && (
        <>
          <Container offsetTop="6rem">
            <h1>{acknowledgments.title}</h1>

            <Wrapper>
              <Content>
                <Win>
                  <Star color="var(--ked-blue)" size="2em" />
                  <TShape color="var(--ked-blue)" rotate={180} size="9em" />
                </Win>
                {!acknowledgments.content ? null : (
                  <Html as="section" className="ked-html">
                    {acknowledgments.content}
                  </Html>
                )}
              </Content>

              <Stack gap="2em">
                <h2>{t("acknowledgments.financial")}</h2>
                {!acknowledgments?.partners?.financial ? null : (
                  <Grid size="18rem">
                    {acknowledgments.partners.financial.map((card) => (
                      <PartnerCard
                        key={card.id}
                        partner={card}
                        role="listitem"
                      />
                    ))}
                  </Grid>
                )}
              </Stack>

              <Stack gap="2em">
                <h2>{t("acknowledgments.technology")}</h2>
                {!acknowledgments?.partners?.financial ? null : (
                  <Grid size="18rem">
                    {acknowledgments.partners.technology.map((card) => (
                      <PartnerCard
                        key={card.id}
                        partner={card}
                        role="listitem"
                      />
                    ))}
                  </Grid>
                )}
              </Stack>
              <Stack gap="2em">
                <h2>{t("acknowledgments.other")}</h2>
                {!acknowledgments?.partners?.financial ? null : (
                  <Grid size="18rem">
                    {acknowledgments.partners.other.map((card) => (
                      <PartnerCard
                        key={card.id}
                        partner={card}
                        role="listitem"
                      />
                    ))}
                  </Grid>
                )}
              </Stack>
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Acknowledgments.displayName = "Acknowledgments";
Acknowledgments.propTypes = {};

Acknowledgments.defaultProps = {};

export default Acknowledgments;
