import React from "react";
import showdown from "showdown";
import PropTypes from "prop-types";

// Custom styles.
import Styles from "./styles";

// Components.
import applyCustomFormatting from "./custom-formatting";

// Main.
const Markdown = ({ children, textAlign, akcentColor, textColor, className, ...props }) => {
  const converter = new showdown.Converter({
    emoji: true,
    tables: true,
    strikethrough: true,
    openLinksInNewWindow: true,
  });

  const createMarkup = content => ({
    __html: converter.makeHtml(applyCustomFormatting(content)),
  });

  return (
    <Styles
      {...props}
      color={textColor}
      akcent={akcentColor}
      textAlign={textAlign}
      className={`ked-markdown ${className || ""}`}
      dangerouslySetInnerHTML={createMarkup(children)}
    />
  );
};

Markdown.displayName = "Markdown";

Markdown.propTypes = {
  children: PropTypes.string,
  textColor: PropTypes.string,
  akcentColor: PropTypes.string,
};

Markdown.defaultProps = {};

export default Markdown;
