import PropTypes from "prop-types";
import styled from "styled-components";

const Styles = styled.div`

  ${({ textAlign, color }) => `
    text-align: ${textAlign};
    color: ${color};
  `};  

  & h1 {
    /* Warn about H1 tag */
    color: red;
    font-size: 1em;
    text-decoration: line-through;
  }

  & > h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  & a {
    z-index: 0;
    color: inherit;
    position: relative;
    text-decoration: none !important;
    
    transition: background-position 0.15s ease-in-out, padding 0.15s ease-in-out;
    background-size: 100% 200%;
    background-position: 0 0;
    word-break: break-word;

    ${({ akcent }) => `
    
      background-image: linear-gradient(
        transparent 0%,
        transparent calc(50% - 2px),
        ${akcent} calc(50% - 2px),
        ${akcent} 100%
      );

      &:hover {
        background-image: linear-gradient(
          transparent 0%,
          transparent calc(50% - 2px),
          ${akcent} calc(50% - 2px),
          ${akcent} 100%
        );
        background-position: 0 100%;
      }
    `};    
  }

  & hr {
    border: none;
    border-bottom: 1px solid var(--ked-gray);
  }
  
  & p {
    margin: 0.5em 0;
    line-height: inherit;
  }

  & strong {
    font-weight: 600;
  }

  & img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 1em auto;
  }

  & blockquote {
    margin: 1.5em 0;
    text-align: left;

    & ul {
      list-style: none;
      padding: 0;
    }

  }

  & ul {
    padding: 0;
    padding-left: 2em;
    list-style: disc;

    & > li {
      margin: 0.3em 0;
    }
  }

  & span.author {
    display: block;
    font-size: 0.9em;
    text-align: right;
  }

  & span.emphasise {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0.5em 0;
    display: inline-block;
  }

  & .center-iframe {
    width: 100%;
    display: flex;
    padding: 1em 0;
    overflow: hidden;
    justify-content: center;    
  }

  & .responsiveEmbed {
    margin: 1.5em 0;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;

    & > iframe {
      top: 0;
      left: 0;
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;


Styles.displayName = "Styles";
Styles.propTypes = {
  color: PropTypes.string,
  akcent: PropTypes.string,
  textAlign: PropTypes.string,
};

Styles.defaultProps = {
  textAlign: "left",
  akcent: "var(--ked-blue)",
  color: "var(--ked-text)",
};

export default Styles;