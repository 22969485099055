import styled, { keyframes } from "styled-components";
import { Stack, Rail } from "@ludekarts/base-ui/layout";


const growWidth = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 105%;
  }
`;

export const Wrapper = styled(Stack)`
  position: relative;

  & h2 {
    scroll-margin-top: 4em;

    @media (max-width: 560px){
      font-size: 9vw;
    }
  }

  & #cta-anchor {
    scroll-margin-top: 40em;
  }

  & .invisible {
    pointer-events: none;
    visibility: hidden;
  }

  & > .tetris {
    transition: tarsnform .2s ease;
    position: absolute;
    z-index: -1;

    &.shape-1 {
      top: 7em;
      left: 0;

      @media (max-width: 460px) {
        width: 8em;
        left: 2em;
      }
    }
    
    /* Down arrow */
    &.shape-2 {
      top: 20em;
      left: 22vw;
      z-index: 0;
      cursor: pointer;
      transition: margin-top .3s ease, filter .3s ease;

      &:hover {
        margin-top: -1em;
        filter: drop-shadow(0 20px 5px rgba(0,0,0,0.4));

        &::before {
          /* Click glass */
          content: "";
          top: 65%;
          left: 50%;
          width: 11em;
          height: 11em;
          border-radius: 50%;
          position: absolute;
          transform: translate3d(-50%,-50%, 0);
        }
      }
      
      @media (max-width: 760px){
        display: none;
      }
    }
  
    &.shape-3 {
      top: 35em;
      left: 2em;
      @media (max-width: 460px) {        
        left: 30vw;
      }
    }
  
    &.shape-4 {
      top: -10em;
      right: 2em;
    }
  
    &.shape-5 {
      top: 60em;
      right: 2em;
    }
  
  }
`;

export const Header = styled.h1`
  text-transform: none;
  font-size: 3.4em;
  margin-left: 2em;

  & > span:nth-child(2) {
    display: block;
    margin-left: 1.5em;
  }  

  & em {
    font-style: normal;
    position: relative;

    &:before {
      left: 0;
      content: "";
      width: 0;
      z-index: -1;
      height: 0.4em;
      right: -0.5em;
      bottom: 0.2em;
      position: absolute;
      background-color: var(--ked-violet);    
      animation: ${growWidth} 1.3s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal forwards;    
    }

    @media (max-width: 760px){
      &:before {
        content: unset;
      }
    }
  }

  @media (max-width: 1150px) {
    font-size: 2.9em;
    margin-left: 1em;
    position: absolute;

    & > span:nth-child(2) {      
      margin-left: 0;
    }  
  }
 
  @media (max-width: 560px) {
    margin: 0;    
  }
  
  @media (max-width: 450px){
    font-size: 10vw;
  }
  
`;

export const KedText = styled.h2`
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: 2.1em;
  white-space: nowrap;

  bottom: 0;
  left: -2em;
  position: absolute;

  filter: drop-shadow(0 0 0 rgba(0,0,0,0.3));
  transition: filter .3s ease;

  &:hover {
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));
  }

  & > span {
    color: white;
    margin-right: auto;
    padding: 0.1em 0.3em;
    display: inline-flex;
    margin-bottom: 0.1em;
    background-color: var(--ked-blue);    
  }

  @media (max-width: 560px) {
    left: 1em;
    right: 1em;
    bottom: 0;
    padding: 0.5em;
    white-space: normal;
    font-size: 6vw !important;
    background-color: var(--ked-blue);
    box-shadow: var(--bui-box-shadow);

    & > span {
      margin: 0;
    }
  }

  @media (max-width: 450px) {

  }
`;

export const Girl = styled.div`
  position: fixed;
  height: 40em;  
  right: 8em;
  top: 2em;

  & > img {
    width: auto;
    max-height: 100%;
    filter: drop-shadow(-1px 4px 6px rgba(0,0,0,0.4));
  }

  @media (max-width: 1150px){
    right: 5em;
  }

  @media (max-width: 990px){
    right: 2em;
  }
  
  @media (max-width: 650px){
    top: 6em;    
  }
  
  @media (max-width: 450px){
    right: 0;
    left: 0;
    height: auto; 

    & > img {
      max-width: 80%;
      margin: 0 auto;
    }
  }
`;

export const Boy = styled.div`
  top: 50em;
  width: 36%;
  z-index: 15;
  position: fixed;
  max-width: 28em;

  & > img {
    filter: drop-shadow(-1px 4px 6px rgba(0,0,0,0.4));
  }

  @media (max-width: 1150px) {
    right: 1em;
    top: 59em;
  }
  
  @media (max-width: 720px) {
    display: none;  
  }
 
  @media (max-width: 560px) {
    top: 9em;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    max-width: unset;
    position: absolute;

    & > img {
      max-width: 20em;
      margin: 0 auto;
      width: 100%;
    }
  }
`;

export const Greenscreen = styled.div`
  width: 35em;
  
  top: 50em;
  right: 2em;
  position: absolute;

  font-size: 1.3em;
  line-height: 1.4em;  
  padding: 3em 3em 4.5em;
  background-color: var(--ked-green);

  & .ked-markdown {
    & h3 {
      color: white;
      font-size: 2em;
    }
  }

  @media (max-width: 1150px) {
    top: unset;
    right: unset;
    position: relative;
    
    width: 100%;
    margin: 45rem 0 5rem;

    & .ked-markdown {
      max-width: 70%;
    }
  }

  @media (max-width: 720px) {
    & .ked-markdown {
      max-width: 100%;
    }
  }
 
  @media (max-width: 560px) {
    padding-top: 14em;
    font-size: 1.1em;

    & .ked-markdown {
      & h3 {                   
        font-size: 9vw;
      }
    }
  }


  @media (max-width: 420px) {    
    font-size: 1.1em;
    margin: 35rem 0 5rem;
    padding: 75% 1.5em 4.5em;

    & .ked-markdown {
      & h3 {      
        font-size: 9vw;
      }
    }
  }

  @media (max-width: 370px) {    
    padding: 60% 1.5em 4.5em;
  }
`;

export const TransactionButton = styled.button`
  bottom: -1em;
  padding: 1.2em;
  min-width: 12em;
  font-weight: 600;
  color: var(--ked-text);
  background-color: var(--ked-orange);
`;

export const TransactionRail = styled(Rail)`
  position: absolute; 
  bottom: -1em;

  @media (max-width: 720px) {
    position: relative;
    font-size: 0.8em;
  }
`;

export const ColorBlock = styled.div`  
  margin-top: 3em;
  font-size: 1.1em;
  position: relative;
  padding: 2em 3.5em;
  background-color: ${({ color }) => color};

  & h2, h3 {
    color: white !important;
  }

  & hr {
    border: none;
    margin: 1.2em 0;
    border-top: 1px solid rgba(0,0,0,0.3);
  }

  & .top-right {
    position: absolute;
    display: inline-flex;
    top:0;
    right: 0;
  }

  & .top-left {
    position: absolute;
    display: inline-flex;
    top:0;
    left: 0;
  }

  & .bottom-left {
    position: absolute;
    display: inline-flex;
    bottom:0;
    left: 0;
  }

  & .bottom-right {
    position: absolute;
    display: inline-flex;
    bottom:0;
    right: 0;
  }

  @media (max-width: 760px) {
    padding: 2em 1.5em 5em;

    & h3 {
      font-size: 1.3em;
    }

    & .top-right {
      width: 6em;
    }
    
    & .bottom-right {
      width: 6em;
    }
  }
`;

export const Label = styled.h2`
  left: 0;
  bottom: 0;
  color: white;
  z-index: 3000;
  position: fixed;
  padding: 0.2em 0.3em;
  background-color: var(--ked-blue);
`;


export const UaFlag = styled.span`
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 1em;
  background-color: #ffd500;

  &::before{
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    content: "";
    position: absolute;
    display: inline-block;
    background-color: #005bbb;
  }
`;