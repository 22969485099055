import { useRef, useEffect } from "react";
import { debounce } from "@ludekarts/utility-belt";


// USAGE:
// 
// import useParallax from "hooks/use-parallax";
// . . .
// 
// useParallax(true);
// 
// <div 
//    data-parallax="0.9" 
//    data-parallax-stop="600"
//    data-parallax-mode="absolute" 
//    data-parallax-start-offset="200" >
//    Parallax Me!
// <div>
// 
// TIP: 
// Best way to determine 'data-parallax-stop' prop is to inspect page in DevTools and
// check translateY property on the element we want to stop.
// 
export default function useParallax(activate = false) {
  const refs = useRef([]);

  const updateScrollPosition = () => {
    refs.current.forEach(element => {
      const animateAbsolutePosition = element.dataset.parallaxMode === "absolute";
      const startOffset = Number(element.dataset.parallaxStartOffset || 0);
      const offsetY = Number(element.dataset.parallaxOffsetY);
      const stopAt = Number(element.dataset.parallaxStop || 0);
      const speed = Number(element.dataset.parallax);
      const translateY = animateAbsolutePosition
        ? window.pageYOffset * speed                    // Move element with absolute scroll position.
        : offsetY - startOffset < window.pageYOffset    // Move element with scroll position relative to it's container.
          ? (offsetY - startOffset - window.pageYOffset) * speed
          : 0;

      if (!stopAt || stopAt < translateY) {
        element.style.transform = `translate3d(0, ${translateY}px, 0)`;
      }
    });
  };

  const setupReferences = elements => {
    refs.current = elements.map(element => {
      const { y } = element.parentNode.getBoundingClientRect();
      element.dataset.parallaxOffsetY = Math.round(y);
      return element;
    });
  };

  const handleResize = debounce(updateScrollPosition, 200);

  // Setup on activate.
  useEffect(() => {
    if (activate === true) {
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", updateScrollPosition);

      const elements = Array.from(document.querySelectorAll("[data-parallax]"));
      setupReferences(elements);
    }

    return () => {
      refs.current = undefined;
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", updateScrollPosition);
    }
  }, [activate]);

  return null;
}


// ---- Helpers ----------------

function resetParallaxProps(element) {
  element.dataset.parallaxOffsetY = 0;
}