import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  opacity: 0;
  overflow: hidden;
  transform: translateY(0.5em);
  transition: opacity .6s ease-out, transform .6s ease-out;

  ${({ animate }) => !animate ? `` : `
    opacity: 1;
    transform: translateY(0);
  ` }
`;

const FadeIn = props => {
  const { children, trigger } = props;
  return (
    <Wrapper animate={trigger}>
      {!trigger ? null : children}
    </Wrapper>
  );
}

FadeIn.displayName = "FadeIn";
FadeIn.propTypes = {
  children: PropTypes.node,
  trigger: PropTypes.bool,
};

FadeIn.defaultProps = {
  trigger: false,
};

export default FadeIn;