import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LShape, TShape, SquareShape } from "components/tetris";

const Wrapper = styled.section`
  margin-top: 4em;
  position: relative;
  scroll-margin-top: 6em;
  
  @media (min-width: 750px) {
    margin-top: 9em;
  }

  & .tetris {
    display: none;
  
    @media (min-width: 820px) {
      z-index: -1;
      display: block;
      position: absolute;
      transition: transform .2s ease;
    }

    &.shape-1 {
      top: -20em;
      left: 50%;
    }
    
    &.shape-2 {
      bottom: 2em;
      right: 30%;
    }
    
    &.shape-3 {      
      top: -5em;
      left: 1em;
    }
    
    &.shape-4 {      
      bottom: 1em;
      right: -1em;
    }
    
    &.shape-5 {      
      top: -10em;
      right: 4em;
    }
   
    &.shape-6 {      
      top: -2em;
      left: 35%;
    }
  }
`;

const Header = styled.h2`
  font-size: 9vw;
  font-weight: 800;  
  margin-bottom: 1em;
  text-transform: uppercase;
  
  padding: 0;
  width: 4em;
  
  @media (min-width: 560px) {
    padding: 1em;
    width: auto;
    font-size: 2.5em;
    display: inline-flex;
    color: var(--ked-text);
    background-color: var(--ked-orange);
  }

  @media (min-width: 1100px) {
    top: 40%;
    left: 50%;    
    margin-bottom: 0;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const Bricks = styled.div`
  width: 100%;
  gap: 3em;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;

  @media (min-width: 560px) {
    gap: 4em;
    
    & > div:nth-child(odd) {
      margin-left: auto;
      margin-right: 10vw;
    }
    
    & > div:nth-child(even) {
      margin-right: auto;
      margin-left: 10vw;
    } 
  }

  @media (min-width: 1100px) {
    gap: 8em 4em;
    grid-template-columns: 1fr 1fr;

    /* Laout boxes in sequence:
    
        X------X
        --X--X--
        X------X
        --X--X--

    */

    & > div:nth-child(4n + 1) {
      margin-left: -10em;
    }

    & > div:nth-child(4n + 2) {
      margin-right: -10em;
    } 
  }
`;

const ProjectsSection = props => {
  const { header, children } = props;
  return (
    <Wrapper id="projects" aria-labelledby="projects-header">
      <Header id="projects-header">{header}</Header>
      <Bricks role="list">
        {children}
      </Bricks>
      <LShape className="tetris shape-1" data-parallax="0.2" data-parallax-mode="absolute" color="var(--ked-yellow)" size="8em" />
      <TShape className="tetris shape-2" data-parallax="-0.3" size="10em" rotate={180} />
      <SquareShape className="tetris shape-3" data-parallax="0.2" data-parallax-mode="absolute" color="var(--ked-lite-blue)" size="4em" />
      <SquareShape className="tetris shape-4" data-parallax="0.3" color="var(--ked-violet)" size="3em" />
      <SquareShape className="tetris shape-5" data-parallax="-0.2" data-parallax-mode="absolute" color="var(--ked-gray)" size="4em" />
    </Wrapper>
  );
}

ProjectsSection.displayName = "ProjectsSection";
ProjectsSection.propTypes = {
  header: PropTypes.string,
};

ProjectsSection.defaultProps = {};

export default ProjectsSection;