import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container } from "@ludekarts/base-ui/layout";

// Components.
import Html from "components/html";
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import ArrowButton from "components/arrow-button";
import { TShape, LShape, SquareShape, PlusShape } from "components/tetris";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 6em 1fr;

  & h1 {
    top: 0.5em;
    background-color: white;
    padding: 0.5em 1em;
    position: absolute;
    color: var(--ked-blue);
    border: 4px solid var(--ked-orange);
  }

  & .shape-t {
    right: 0;
    position: absolute;
  }

  & .shape-l {
    top: 10em;
    right: 0;
    position: absolute;
  }

  & .shape-s-1 {
    top: 10em;
    right: -3.3em;
    position: absolute;
  }

  & .shape-s-2 {
    top: 3.3em;
    right: 10em;
    position: absolute;
  }

  & .shape-p {
    right: 2.2em;
    bottom: 3em;
    position: absolute;
  }

  & .shape-l-2 {
    left: 6em;
    bottom: 1.5em;
    position: absolute;
  }

  & .arrow-button {
    top: 8em;
    left: 5em;
    color: white;
    position: absolute;
  }

  & .ked-html {
    margin-bottom: 2em;
    padding: 8em 6em 6em;
    background-color: var(--ked-orange);

    & h2 {
      color: var(--ked-text);
    }
  }

  @media (max-width: 870px) {
    & .shape-t,
    & .shape-l,
    & .shape-s-1,
    & .shape-s-2 {
      display: none;
    }

    & .arrow-button {
      top: 10rem;
    }

    & .ked-markdown {
      padding-top: 12rem;
    }

    & h1 {
      width: 25rem;
    }
  }

  @media (max-width: 590px) {
    grid-template-columns: 1fr;

    & > div:first-child {
      display: flex;
    }

    & h1 {
      top: 0;
      width: 100%;
      font-size: 7vw;
      word-spacing: 8rem;
      position: relative;
    }

    & .ked-markdown {
      padding: 4rem 2rem 2rem;
    }

    & .arrow-button {
      top: 23vw;
      left: 2rem;
    }

    & .shape-p,
    & .shape-l-2 {
      display: none;
    }
  }
`;

const PrivacyPolicy = () => {
  const { t } = useTranslation(); // Required to re-render on language change.
  const lang = getCurrentLanguage();
  const [privacy] = useApi(api.getPrivacyPolicyContent, lang);
  return (
    <FadeIn trigger={!!privacy}>
      {privacy && (
        <>
          <Container offsetTop="6rem">
            <Wrapper>
              <div>
                <h1>{privacy.title}</h1>
                <ArrowButton
                  className="arrow-button lift-panel"
                  color="var(--ked-blue)"
                  as={Link}
                  to="/"
                  size="5em"
                  left
                />
              </div>
              <Html
                as="section"
                className="ked-html"
                akcentColor="var(--ked-lite-gray)"
              >
                {privacy.content}
              </Html>
              <TShape className="shape-t" color="white" size="10em" />
              <SquareShape
                className="shape-s-1"
                color="var(--ked-orange)"
                size="3.3em"
              />
              <SquareShape className="shape-s-2" color="white" size="3.3em" />
              <LShape
                className="shape-l"
                color="white"
                size="6.55em"
                rotate={90}
              />
              <PlusShape
                className="shape-p"
                color="white"
                size="7em"
                rotate={45}
              />
              <LShape
                className="shape-l-2"
                color="white"
                size="6.55em"
                rotate={0}
              />
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

PrivacyPolicy.displayName = "PrivacyPolicy";
export default PrivacyPolicy;
