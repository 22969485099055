import { isObject } from "@ludekarts/utility-belt";
import config from "config";

const assetsLocation = config.backendUrl;
const aliasesToResolve = {
  url: assetsLocation,
};

export default function resolveAssets(source) {
  if (isObject(source)) {
    return Object.keys(source).reduce((acc, key) => {
      const currentValue = source[key];
      if (aliasesToResolve[key]) {
        acc[key] = aliasesToResolve[key] + currentValue;
      } else if (isObject(currentValue)) {
        acc[key] = resolveAssets(currentValue);
      } else if (Array.isArray(currentValue)) {
        acc[key] = currentValue.map(resolveAssets);
      } else {
        acc[key] = currentValue;
      }
      return acc;
    }, {});
  } else if (Array.isArray(source)) {
    return source.map(resolveAssets);
  } else {
    return source;
  }
}
