import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container } from "@ludekarts/base-ui/layout";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Html from "components/html";
import ArrowButton from "components/arrow-button";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  position: relative;

  color: var(--ked-blue);
  grid-template-columns: 1fr;

  @media (min-width: 750px) {
    grid-template-columns: 6em 1fr;
  }

  & h1 {
    top: 0.5em;
    width: 100%;
    color: white;
    position: absolute;
    padding: 0.5em 1em 0.5em 2em;
    background-color: var(--ked-violet);

    @media (min-width: 750px) {
      width: auto;
      padding: 0.5em 1em;

      &:before {
        top: 100%;
        left: 0;
        content: "";
        width: 2.4em;
        height: 4.8em;
        position: absolute;
        background-color: var(--ked-violet);
      }
    }
  }

  & .arrow-button {
    top: 1.6em;
    left: 0em;
    color: white;
    position: absolute;

    @media (min-width: 750px) {
      top: 13.5em;
      left: 0.5em;
    }
  }

  & .ked-html {
    padding: 8em 1em 1em;

    @media (min-width: 750px) {
      padding: 8em 7em 4em 2em;

      &:before {
        right: 0;
        bottom: 3em;
        content: "";
        width: 5.7em;
        height: 15em;
        position: absolute;
        background-color: var(--ked-violet);
      }
    }
  }
`;

const Mission = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [mission] = useApi(api.getMissionContent, lang);
  return (
    <FadeIn trigger={!!mission}>
      {mission && (
        <>
          <Container offsetTop="6rem">
            <Wrapper>
              <div>
                <h1>{mission.title}</h1>
                <ArrowButton
                  className="arrow-button lift-panel"
                  color="var(--ked-violet)"
                  as={Link}
                  to="/#mission"
                  size="5em"
                  left
                />
              </div>
              <Html
                as="section"
                className="ked-html"
                akcentColor="var(--ked-violet)"
                textColor="var(--ked-blue)"
              >
                {mission.content}
              </Html>
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Mission.displayName = "Mission";
export default Mission;
