import React from "react";
import styled from "styled-components";

const ShapeWrapper = styled.span`
  display: inline-flex;
  & > svg {
    pointer-events: none;
  }
`;

export const LShape = ({ color = "#DDDDDD", size = "13.35em", rotate = "0", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 40 40" fill={color} transform={`rotate(${size / 2, size / 2, rotate})`}>
      <polygon points="20,20 20,0 0,0 0,20 0,40 20,40 40,40 40,20 " />
    </svg>
  </ShapeWrapper>
);

export const TShape = ({ color = "#DDDDDD", size = "20em", rotate = "0", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 60 40" fill={color} transform={`rotate(${size / 2, size / 2, rotate})`}>
      <polygon points="40,0 20,0 0,0 0,20 20,20 20,40 40,40 40,20 60,20 60,0 " />
    </svg>
  </ShapeWrapper>
);

export const L2Shape = ({ color = "#DDDDDD", size = "20em", rotate = "0", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 60 60" fill={color} transform={`rotate(${size / 2, size / 2, rotate})`}>
      <polygon points="40,0 20,0 0,0 0,20 0,40 0,60 20,60 20,40 20,20 40,20 60,20 60,0 " />
    </svg>
  </ShapeWrapper>
);

export const PlusShape = ({ color = "#DDDDDD", size = "20em", rotate = "0", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 60 60" fill={color} transform={`rotate(${size / 2, size / 2, rotate})`}>
      <polygon points="40,20 40,0 20,0 20,20 0,20 0,40 20,40 20,60 40,60 40,40 60,40 60,20 " />
    </svg>
  </ShapeWrapper>
);

export const SquareShape = ({ color = "#DDDDDD", size = "6.6em", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 20 20" fill={color}>
      <rect width="20" height="20" />
    </svg>
  </ShapeWrapper>
);

export const RectShape = ({ color = "#DDDDDD", size = "6.6em", rotate = "0", ...props }) => (
  <ShapeWrapper {...props}>
    <svg width={size} viewBox="0 0 40 20" fill={color} transform={`rotate(${rotate})`}>
      <rect width="40" height="20" />
    </svg>
  </ShapeWrapper>
);
