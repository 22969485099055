import React from "react";
import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  gap: 1em 3em;
  display: grid;  
  
  &.donate {
    
    grid-template-columns: 1fr;   

    & .radio-rail {
      width: 100%;
      @media (max-width: 1240px) {
        margin-bottom: 1em;        
      }
    }

    & .user-data {
      max-width: 22rem;
      @media (max-width: 980px) {
        max-width: unset;
        width: 100%;
      }
    }
  }

  &.contact {
    grid-template-columns: 1fr;  
  
    @media (min-width: 750px) {
      grid-template-columns: 23em 1fr;  
    }
  }

  & input[type="text"], 
  & input[type="email"], 
  & select, 
  & textarea {
    width: 100%;
    padding: 0.5em;
    transition: border .3s ease;
    border: 3px solid var(--ked-blue);

    &:focus {
      outline: none;
      border: 3px solid var(--ked-green);
    }
  }

  & textarea {
    height: 100%;
  }
`;

export const InteractiveInput = styled.label`
  position: relative;
  background-color: white;
   
  &:hover:before {
    bottom: 100%;
    background-color: var(--ked-blue);
  }
  
  &:focus-within:before {
    bottom: 100%;
    background-color: var(--ked-green);
  }

  &:before {
    bottom: 0;
    z-index: -1;
    color: white;
    padding: 0 0.5em;
    font-size: 0.9em;
    position: absolute;
    content: attr(data-label);
    transition: bottom .3s ease, background-color .3s ease; 
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  color: white;
  padding: 1em;
  font-weight: 600;
  margin-top: auto;  
  text-transform: uppercase;
  background-color: var(--ked-green);
  border: 3px solid var(--ked-green);
  transition: filter .3s ease, transform .3s ease, box-shadow .3s ease;

  &.submit-dektop {
    display: none;
  }

  &.submit-mobile {
    margin-top: 1.8em;    
  }

  @media (min-width: 750px) {
    &.submit-dektop {
      display: block;
    }
    
    &.submit-mobile {
      margin-top: none;
      display: none;
    }
  }

  &:focus {
    outline: none;
    filter: brightness(0.8);
  }
`;


const RadioBoxWrapper = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  & > input {
    position: absolute;
    opacity: 0;
    
    &:checked ~ div.box {
      color: white;      
      border: 3px solid var(--ked-blue);
      background-color: var(--ked-blue);

      & svg {
        fill: white;
      }
    }
  }

  & div.box {
    width: 100%;
    padding: 1em;
    font-weight: 600;
    min-width: 16rem;
    text-align: center;
    color: var(--ked-blue);    
    background-color: white;
    border: 3px solid var(--ked-blue);
    transition: background-color .3s ease, color .3s ease, filter .3s ease;

    & svg {
      fill: var(--ked-blue);
    }

    &:hover {
      filter: brightness(0.85);
    }
  }
`;

export const RadioBox = props => {
  const { children, name, id, ...rest } = props;
  return (
    <RadioBoxWrapper htmlFor={id}>
      <input id={id} type="radio" name={name} {...rest} />
      <div className="box">{children}</div>
    </RadioBoxWrapper>
  );
}