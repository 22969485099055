import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  height: ${({ height }) => height};
`;

const Banner = props => {
  const { source } = props;
  const [height, setHeight] = useState("auto");

  const resetHeight = () => {
    setHeight("auto");
  };

  useEffect(() => {
    source && setHeight(source.desktop.height + "px");
  }, [source]);

  return (
    !source || !source.showBanner ? null :
      <Wrapper height={height}>
        <picture onLoad={resetHeight}>
          <source media="(min-width: 670px)" srcSet={source.desktop.url} />
          <img src={source.mobile.url} alt="Wyrównujemy szanse wszystkich dzieci w Polsce tworząc darmowe, otwarte zasoby edukacyjne" />
        </picture>
      </Wrapper>
  );
}

Banner.displayName = "Banner";
Banner.propTypes = {
  source: PropTypes.object
};

Banner.defaultProps = {};

export default Banner;