import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PlusShape } from "components/tetris";

const Wrapper = styled.div`
  left: 0;
  right: 0;
  top: 4.8em;
  color: white;
  padding: 1em;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: ${({ isError }) =>
    isError ? "var(--ked-red)" : "var(--ked-green)"};

  ${({ show }) =>
    show
      ? `
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  `
      : `
    opacity: 0;
    pointer-events: none;
    transform: translateY(-0.5em);
  `}

  & > button:last-child {
    padding: 0;
    display: flex;
    background: none;
    margin-left: 0.8em;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

/*
// USAGE:

  import Prompter from "components/prompter";
  . . .

  const [prompterContent, setPrompter] = useState();
  . . .

  setPrompter({ error: "Error message" });
  setPrompter({ info: "Info message" });

  <Prompter source={prompterContent} onClose={setPrompter(undefined)} />
*/

const Prompter = (props) => {
  const { source, onClose } = props;
  const [show, toggle] = useState();
  const close = () => {
    toggle(false);
    setTimeout(onClose, 350);
  };

  useEffect(() => {
    Boolean(source) && toggle(true);
  }, [source]);

  return (
    <Wrapper show={show} isError={Boolean(source?.error)}>
      <span>{source?.error || source?.info}</span>
      <button onClick={close}>
        <PlusShape rotate={45} size="1em" />
      </button>
    </Wrapper>
  );
};

Prompter.displayName = "Prompter";
Prompter.propTypes = {
  onClose: PropTypes.func,
  source: PropTypes.object,
};

Prompter.defaultProps = {};

export default Prompter;
