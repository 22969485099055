import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Rail } from "@ludekarts/base-ui/layout";
import { Text } from "@ludekarts/base-ui";

const Wrapper = styled.div`
  display: inline-grid;
  grid-template-rows: 8em auto 1fr;
  align-content: center;

  & h2 {
    font-size: 1.3em;
    padding: 0.5em 0 0.2em;
    color: var(--ked-text-gray);

    &.space {
      padding: 0.5em 0.6em 0.2em;
    }
  }

  & > p {
    height: 100%;
    padding: 1em;
    color: white;
    display: flex;
    font-weight: 500;
    align-items: flex-end;
    justify-content: start;
    background-color: ${({ color }) => color};
  }

  & a,
  & section {
    color: var(--ked-text-gray);
    padding: 0 0.8em;
  }
`;

const MemberCard = (props) => {
  const { member, color, showBio, lang = "pl" } = props;
  return (
    <Wrapper color={color}>
      <p>{member[`job_title_${lang}`]}</p>
      {!member.linkedin ? (
        <h2 className="space">{member.name}</h2>
      ) : (
        <a href={member.linkedin} target="_blank">
          <Rail as="h2" gap="0.5em">
            <LinkedInIcon color="var(--ked-text-gray)" />
            <span>{member.name}</span>
          </Rail>
        </a>
      )}

      {showBio ? (
        <section>{member[`description_${lang}`]}</section>
      ) : (
        <Text as="a" href={`mailto:${member.email}`} ellypsis>
          {member.email}
        </Text>
      )}
    </Wrapper>
  );
};

MemberCard.displayName = "MemberCard";
MemberCard.propTypes = {
  member: PropTypes.object,
  color: PropTypes.string,
  showBio: PropTypes.bool,
};

MemberCard.defaultProps = {
  color: "var(--ked-gray)",
  showBio: false,
};

export default MemberCard;

// ---- ICONS ----------------

function LinkedInIcon({ size = "1em", color = "#2867B2" }) {
  return (
    <svg width={size} viewBox="0 0 45 45">
      <path
        fill={color}
        d="M42.128,44.973c-13.085,0-26.17,0-39.255,0
	c-0.049-0.023-0.096-0.059-0.148-0.069C1.004,44.536,0.001,43.299,0,41.537c0-1.775,0-3.551,0-5.327
	C0,25.249-0.001,14.287,0.002,3.325c0-1.429,0.678-2.446,1.964-3.052c0.286-0.134,0.603-0.202,0.906-0.3c13.085,0,26.17,0,39.255,0
	c0.091,0.029,0.18,0.067,0.272,0.087c1.516,0.323,2.598,1.633,2.598,3.179c0.003,12.822,0.003,25.645-0.001,38.467
	c0,1.202-0.544,2.13-1.56,2.748C43.042,44.693,42.567,44.803,42.128,44.973z M24.209,38.252c0-0.21,0-0.376,0-0.541
	c0-3.429-0.005-6.855,0.005-10.283c0.001-0.558,0.036-1.119,0.103-1.674c0.276-2.331,1.476-3.465,3.768-3.587
	c1.696-0.09,2.878,0.716,3.28,2.371c0.206,0.847,0.304,1.738,0.313,2.61c0.034,3.541,0.014,7.08,0.014,10.62c0,0.16,0,0.321,0,0.472
	c2.248,0,4.426,0,6.624,0c0.007-0.11,0.016-0.192,0.016-0.273c0.005-3.835,0.02-7.668,0.011-11.501
	c-0.004-1.735-0.104-3.465-0.582-5.149c-0.653-2.301-2.012-3.964-4.385-4.586c-0.963-0.252-1.984-0.353-2.983-0.385
	c-2.694-0.087-4.782,1.048-6.26,3.299c-0.036,0.055-0.043,0.129-0.063,0.194c-0.052-0.029-0.104-0.059-0.155-0.089
	c0-0.953,0-1.906,0-2.865c-2.146,0-4.228,0-6.338,0c0,7.131,0,14.241,0,21.368C19.787,38.252,21.968,38.252,24.209,38.252z
	 M13.31,38.252c0-7.144,0-14.242,0-21.363c-2.217,0-4.406,0-6.609,0c0,7.135,0,14.245,0,21.363
	C8.913,38.252,11.093,38.252,13.31,38.252z M10.016,13.909c2.13-0.004,3.858-1.744,3.854-3.879c-0.004-2.1-1.747-3.83-3.86-3.83
	c-2.125,0-3.87,1.752-3.859,3.877C6.162,12.176,7.913,13.913,10.016,13.909z"
      />
    </svg>
  );
}
