import { useEffect } from "react";
import { changeLanguage } from "components/i18n";
import { queryToObject, updateQuery } from "@ludekarts/utility-belt";

export default function useUrlLanguage() {
  useEffect(() => {
    const { lang } = queryToObject(window.location.search);
    if (lang) {
      changeLanguage(lang);
      window.location.search = updateQuery(window.location.search, { lang: undefined });
    }
  }, []);
}