import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.a`
  position: relative;
  filter: grayscale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease;

  &:hover {
    filter: grayscale(0);
    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:before {
    top: 110%;
    opacity: 0;
    font-size: 0.7em;
    padding: 0 0.5em;
    position: absolute;
    color: white;
    white-space: nowrap;
    transform: translateY(-0.5em);
    background-color: var(--ked-black);
    transition: opacity 0.3s ease, transform 0.3s ease;
    ${({ partnerType }) => (!partnerType ? `` : `content: "${partnerType}";`)}
  }

  & > img {
    max-width: 80%;
    height: auto;
  }
`;

const PartnerCard = (props) => {
  const { partner } = props;
  const { t } = useTranslation();
  return (
    <Wrapper
      target="_blank"
      href={partner.website}
      partnerType={getPartnerType(partner.type, t)}
    >
      <img src={partner.logo} />
    </Wrapper>
  );
};

PartnerCard.displayName = "PartnerCard";
PartnerCard.propTypes = {
  partner: PropTypes.object,
};

PartnerCard.defaultProps = {};

export default PartnerCard;

// ---- Helpers ----------------

function getPartnerType(type, t) {
  return type === "project"
    ? undefined
    : type === "financial"
    ? t("partner.financial")
    : type === "technology"
    ? t("partner.technology")
    : type === "substantive"
    ? t("partner.substantive")
    : undefined;
}
