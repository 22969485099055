import React from "react";
import styled from "styled-components";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container } from "@ludekarts/base-ui/layout";

// Components.
import Html from "components/html";
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import MemberCard from "components/cards/member";

const Wrapper = styled.div`
  gap: 3em;
  display: grid;
  padding-bottom: 3em;
  grid-template-columns: 1fr;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const colors = [
  "var(--ked-blue)",
  "var(--ked-green)",
  "var(--ked-violet)",
  "var(--ked-lite-blue)",
  "var(--ked-yellow)",
  "var(--ked-orange)",
  "var(--ked-red)",
];

const Board = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [board] = useApi(api.getBoardContent, lang);

  return (
    <FadeIn trigger={!!board}>
      {board && (
        <>
          <Container offsetTop="6rem">
            <h1>{board.title}</h1>
            <Wrapper>
              <Html as="section" akcentColor="var(--ked-lite-blue)">
                {board.content}
              </Html>
              {board.members.map((member, index) => (
                <MemberCard
                  showBio
                  lang={lang}
                  key={member.id}
                  member={member}
                  color={colors[index]}
                />
              ))}
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Board.displayName = "Board";
Board.propTypes = {};

Board.defaultProps = {};

export default Board;
