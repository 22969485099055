import config from "config";
const { backendUrl } = config;

export default function customFormatting(html) {
  return (
    html
      // Resolve relative links.
      .replace(/\/uploads\//g, `${backendUrl}/uploads/`)
      // Line space.
      .replace(/--\/\/--/g, `<hr class="space"/>`)
      // Super emphasise.
      .replace(/\^\^(.+?)\^\^/g, (_, content) => `<span class="emphasise">${content}</span>`)
      // Quote author.
      .replace(/---(.+?):(.*?)-/g, (_, author, note) => `<span class="author"><strong>${author}</strong><br/><span>${note}</span></span>`)
      // Insert iframes.
      .replace(/@embed\((.+?)\)/g, (match, content) => {
        const [url, height, width] = content.split("~");
        return `<div class="center-iframe"><iframe src="${url}" frameborder="0" width="${width || "100%"}" allowfullscreen ${height ? `height="${height}"` : ""}></iframe></div>`
      })
      // Insert embeds (new embeds styles).
      .replace(/https:\/\/www\.youtube\.com\/watch\?v=([\w\d]+)/g, (_match, ytid) => {
        const target = `https://www.youtube.com/embed/${ytid}`;
        return `<div class="responsiveEmbed"><iframe src="${target}" frameborder="0" allowfullscreen></iframe></div>`;
      })
  );
}
