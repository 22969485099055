import { useState, useEffect } from "react";

export default function useApi(methodOrConfig, ...params) {
  const [data, setData] = useState(methodOrConfig.initialData);
  const [error, setError] = useState();
  const method = typeof methodOrConfig === "function"
    ? methodOrConfig
    : methodOrConfig.method;

  useEffect(() => {
    let unmounted = false;
    if (method && !unmounted && !error) {
      method(...params)
        .then(data => {
          setData(data);
        })
        .catch(error => {
          setError(error);
          console.error(error);
        });
    }
    return () => {
      unmounted = true;
    }
  }, params);

  return [data, error];
}