import { parseResponse, objectToFetchBody } from "@ludekarts/utility-belt";
import config from "config";

// Hook.
export { default as useApi } from "./use-api";

// Helpers.
import assetsResolver from "helpers/assets-resolver";
import {
  formatContact,
  formatPartners,
  formatDocuments,
  formatPageContent,
  formatBoardMembers,
  watchForServerErrors,
} from "./utils";

const backendUrl = config.backendUrl;
const recaptchaKey = config.recaptchaKey;

export const pages = {
  team: "team",
  media: "media",
  board: "board",
  donate: "donate",
  contact: "contact",
  mission: "mission",
  partners: "partners",
  projects: "projects",
  philosophy: "philosophy",
  cooperation: "cooperation",
  achievements: "achievements",
  privacyPolicy: "privacy-policy",
  testimonialsCm: "testimonials-cm",
  testimonialsPie: "testimonials-pie",
};

// Main API.
const api = {
  getPageContent(pageName, lang) {
    return fetch(
      `${backendUrl}/wp-json/wp/v2/posts?tag_name=${lang}&category_name=${pageName}&acf_format=standard&_fields=id,title,slug,content,excerpt,acf`
    )
      .then(parseResponse)
      .then(formatPageContent);
  },

  async getLandingContent(lang) {
    const [[team], [mission], [philosophy], projects, achievements] =
      await Promise.all([
        api.getPageContent(pages.team, lang),
        api.getPageContent(pages.mission, lang),
        api.getPageContent(pages.philosophy, lang),
        api.getPageContent(pages.projects, lang),
        api.getPageContent(pages.achievements, lang),
      ]);

    return {
      team,
      mission,
      projects,
      philosophy,
      achievements,
      products: projects.slice(0, 3),
    };
  },

  async getCollaborationContent(lang) {
    const [[schools, business], testimonialsCm, testimonialsPie] =
      await Promise.all([
        api.getPageContent(pages.cooperation, lang),
        api.getPageContent(pages.testimonialsCm, lang),
        api.getPageContent(pages.testimonialsPie, lang),
      ]);

    return { schools, business, testimonialsCm, testimonialsPie };
  },

  async getMediaContent(lang) {
    const [media] = await api.getPageContent(pages.media, lang);
    return media;
  },

  async getMissionContent(lang) {
    const [mission] = await api.getPageContent(pages.mission, lang);
    return mission;
  },

  async getPhilosophyContent(lang) {
    const [philosophy] = await api.getPageContent(pages.philosophy, lang);
    return philosophy;
  },

  getContacts() {
    return fetch(`${backendUrl}/wp-json/wp/v2/contacts?_fields=id,title,acf`)
      .then(parseResponse)
      .then(formatContact);
  },

  getBoardMembers() {
    return fetch(`${backendUrl}/wp-json/wp/v2/board?_fields=title,acf`)
      .then(parseResponse)
      .then(formatBoardMembers);
  },

  async getBoardContent(lang) {
    const [board] = await api.getPageContent(pages.board, lang);
    const members = await api.getBoardMembers();
    return { ...board, members };
  },

  async getContactContent(lang) {
    const [contact] = await api.getPageContent(pages.contact, lang);
    const contacts = await api.getContacts();
    return { ...contact, contacts };
  },

  sendContactForm({ firstName, lastName, email, message, subject, rcToken }) {
    const requestConfig = {
      method: "POST",
      body: objectToFetchBody({
        email,
        message,
        subject,
        rcToken,
        lastName,
        firstName,
      }),
    };

    return fetch(`${backendUrl}/wp-json/ked/v1/contact`, requestConfig)
      .then(parseResponse)
      .then(watchForServerErrors);
  },

  async getDonateContent(lang) {
    const [donate] = await api.getPageContent(pages.donate, lang);
    return donate;
  },

  getDocumentsContent() {
    return fetch(`${backendUrl}/wp-json/wp/v2/documents?_fields=id,title,acf`)
      .then(parseResponse)
      .then(formatDocuments);
  },

  async getPrivacyPolicyContent(lang) {
    const [privacy] = await api.getPageContent(pages.privacyPolicy, lang);
    return privacy;
  },

  getThanksContent(lang) {
    return fetch(`${backendUrl}/thanks-page?_locale=${lang}`)
      .then(parseResponse)
      .then(assetsResolver);
  },

  async getAcknowledgmentsContent(lang) {
    const [[acknowledgments], partners] = await Promise.all([
      api.getPageContent(pages.partners, lang),
      api.getPartners(),
    ]);
    return { ...acknowledgments, partners };
  },

  getPartners() {
    return fetch(
      `${backendUrl}/wp-json/wp/v2/partners?acf_format=standard&_fields=id,title,acf`
    )
      .then(parseResponse)
      .then(formatPartners);
  },

  async getProject(slug, lang) {
    const projects = await api.getPageContent(pages.projects, lang);
    return projects.find((project) => project.uid === slug);
  },

  connectReCaptcha() {
    return `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
  },

  goToPayment(p24token) {
    const { isProduction, token } = p24token;
    return `https://${
      isProduction ? "secure" : "sandbox"
    }.przelewy24.pl/trnRequest/${token}`;
  },

  donate(firstName, lastName, email, amount, purpose, rcToken) {
    const requestConfig = {
      method: "POST",
      body: objectToFetchBody({
        firstName,
        lastName,
        email,
        amount,
        purpose,
        rcToken,
      }),
    };

    return fetch(`${backendUrl}/wp-json/ked/v1/donation/`, requestConfig)
      .then(parseResponse)
      .then(watchForServerErrors);
  },
};

export default api;
