import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  color: white;
  padding: 1em;
  max-width: 300px;
  position: relative;
  background-color: var(--ked-blue);

  & a {
    color: white;
    display: flex;
    align-items: center;
    margin-top: -1em;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    & > img {
      opacity: 1;
      position: absolute;
      transform: translateY(0);
      transition: transform 0.3s ease, opacity.3s ease;
    }

    & > div.content {
      opacity: 0;
      position: absolute;
      transform: translateY(1em);
      transition: transform 0.3s ease, opacity.3s ease;
    }

    &:hover {
      & > img {
        opacity: 0;
        transform: translateY(-1em);
      }

      & > div.content {
        opacity: 1;
        transform: translateY(0);
      }

      & .arrow-button {
        transform: translateY(-1em);
        box-shadow: 0px 19px 19px -13px rgba(0, 0, 0, 0.4);
      }
    }

    &:before {
      top: 1em;
      width: 100%;
      position: absolute;
      padding: 0.2em 0.5em;
      text-transform: uppercase;
      background-color: rgba(0, 0, 0, 0.4);
      ${({ projectType }) => (!projectType ? `` : `content: "${projectType}";`)}
    }
  }

  & .arrow-button {
    bottom: -4em;
    position: absolute;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
`;
