import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Layout.
import { Stack } from "@ludekarts/base-ui/layout";

// Components.
import Html from "components/html";
import { Text } from "@ludekarts/base-ui";
import { L2Shape, PlusShape } from "components/tetris";
import ArrowButton from "components/arrow-button";

// Styled.
const Wrapper = styled.section`
  display: flex;
  margin-top: 6em;
  position: relative;
  align-items: center;
  scroll-margin-top: 6em;
  justify-content: center;

  h2 {
    color: var(--ked-violet);
  }

  @media (min-width: 1100px) {
    margin-top: 8em;
    height: 36rem;

    h2 {
      color: var(--ked-blue);
    }
  }

  & .arrow-button {
    background-color: var(--ked-violet);
    @media (min-width: 1100px) {
      background-color: var(--ked-blue);
    }
  }

  & > .tetris {
    display: none;

    @media (min-width: 1100px) {
      z-index: -1;
      display: block;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &.shape-1 {
      top: 50px;
      left: 0;
    }

    &.shape-2 {
      right: 0;
      bottom: 50px;
    }

    &.shape-3 {
      left: 8em;
      bottom: 8em;
    }
  }

  & p {
    font-size: 1.2em;
    max-width: 45rem;
    font-weight: 500;
    line-height: 1.5em;
    text-align: center;
  }
`;

const MissionSection = (props) => {
  const { header, children } = props;
  return (
    <Wrapper id="mission" aria-labelledby="mission-header">
      <L2Shape
        className="tetris shape-1"
        color="var(--ked-violet)"
        data-parallax="0.3"
        data-parallax-start-offset="300"
      />
      <Stack gap="1em" className="--stack-center">
        <Text
          id="mission-header"
          as="h2"
          size="2.8em"
          lh="1em"
          weight="800"
          transform="uppercase"
        >
          {header}
        </Text>
        <Html akcentColor="var(--ked-violet)" textColor="var(--ked-blue)">
          {children}
        </Html>
        <ArrowButton
          as={Link}
          to="/mission"
          size="5em"
          className="lift-panel arrow-button"
        />
      </Stack>
      <L2Shape
        className="tetris shape-2"
        color="var(--ked-violet)"
        rotate={180}
        data-parallax="-0.3"
      />
      <PlusShape
        className="tetris shape-3"
        color="var(--ked-yellow)"
        data-parallax="-0.4"
        data-parallax-start-offset="200"
        size="4em"
      />
    </Wrapper>
  );
};

MissionSection.displayName = "MissionSection";
MissionSection.propTypes = {
  header: PropTypes.string,
};

MissionSection.defaultProps = {};

export default MissionSection;
