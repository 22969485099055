import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Stack, Rail } from "@ludekarts/base-ui/layout";
import { Checkbox } from "@ludekarts/base-ui/buttons";
import { Collapse } from "@ludekarts/base-ui";

const Wrapper = styled.label`
  cursor: pointer;
  font-size: 0.9em;
  
  & button {
    padding: 0%;
    background: none;
    font-size: 0.9em;
  }

  & .text {
    margin: 0em 1em 1em 2.5em;
  }
`;


const RodoAgreement = props => {
  const { color } = props;
  const { t } = useTranslation();
  const [show, toggleShow] = useState(false);
  return (
    <Wrapper fotHtml="accept-rodo">
      <Stack>
        <Rail as="span" >
          <Checkbox name="rodo" color={color} required />
          <span>
            <Trans i18nKey="rodo.acknowledgment">.<Link to="/privacy-policy">.</Link>.</Trans>
            <button type="button" onClick={() => toggleShow(!show)}>({t("rodo.more-button")})</button>
          </span>
        </Rail>
        <Collapse open={show} >
          <p className="text">
            <Trans i18nKey="rodo.explanation">.<Link to="/privacy-policy">.</Link>.</Trans>
          </p>
        </Collapse>
      </Stack>
    </Wrapper>

  );
}

RodoAgreement.displayName = "RodoAgreement";
RodoAgreement.propTypes = {
  color: PropTypes.string,
};

RodoAgreement.defaultProps = {};

export default RodoAgreement;