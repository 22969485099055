import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container } from "@ludekarts/base-ui/layout";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Html from "components/html";
import ArrowButton from "components/arrow-button";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;

  @media (min-width: 750px) {
    grid-template-columns: 6em 1fr;
  }

  & h1 {
    top: 0;
    color: white;
    font-size: 9vw;
    padding: 0.5em 1em;
    position: absolute;
    background-color: var(--ked-blue);

    @media (min-width: 750px) {
      top: 0.5em;
      font-size: 2.8em;
    }
  }

  & .arrow-button {
    bottom: 0;
    right: 1em;
    color: white;
    position: absolute;

    @media (min-width: 750px) {
      top: 8em;
      left: 5em;
      right: unset;
      bottom: unset;
    }
  }

  & .ked-html {
    margin-bottom: 2em;
    padding: 20vw 2em 2em;
    background-color: var(--ked-green);

    @media (min-width: 750px) {
      padding: 8em 6em 6em;
    }
  }
`;

const Philosophy = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [philosophy] = useApi(api.getPhilosophyContent, lang);
  return (
    <FadeIn trigger={!!philosophy}>
      {philosophy && (
        <>
          <Container offsetTop="6rem">
            <Wrapper>
              <div>
                <h1>{philosophy.title}</h1>
                <ArrowButton
                  className="arrow-button lift-panel"
                  color="var(--ked-blue)"
                  as={Link}
                  to="/"
                  size="5em"
                  left
                />
              </div>
              <Html
                as="section"
                textColor="white"
                className="ked-html"
                akcentColor="var(--ked-blue)"
              >
                {philosophy.content}
              </Html>
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Philosophy.displayName = "Philosophy";
export default Philosophy;
