import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pl from "../../../locale/pl.json";
import en from "../../../locale/en.json";

const initLang = JSON.parse(
  localStorage.getItem("ked.website") || `{ "language": "pl" }`
).language;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
    lang: initLang,
    fallbackLng: initLang,
    interpolation: {
      escapeValue: false,
    },
  });

export function changeLanguage(lang) {
  i18n.changeLanguage(lang);
  localStorage.setItem("ked.website", JSON.stringify({ language: lang }));
}

export function getCurrentLanguage() {
  return i18n.language;
}

