import React from "react";
import Html from "components/html";
import styled from "styled-components";
import { LShape } from "components/tetris";

const Wrapper = styled.section`
  margin-top: 5em;
  position: relative;
  scroll-margin-top: 5em;

  & img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  @media (min-width: 680px) {
    & img {
      width: 80%;
      margin: 0 auto 0 0;
    }

    height: 35em;
  }

  @media (min-width: 850px) {
    height: 52em;
    margin-top: 10em;

    & img {
      width: 55%;
      height: auto;
    }
  }

  & p {
    max-width: 44ch;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.5em;

    @media (min-width: 750px) {
      font-size: 1.2em;
    }
  }

  & .ked-html {
    padding: 2em;
    margin-top: 1em;
    position: relative;
    background-color: var(--ked-orange);

    width: 100%;
    margin: 0 auto;

    & p {
      max-width: none;
    }

    @media (min-width: 680px) {
      width: 80%;
      margin: 0 0 0 auto;
    }

    @media (min-width: 850px) {
      right: 0;
      top: -3em;
      width: 26em;
      padding: 3em;
      position: absolute;
    }

    @media (min-width: 1100px) {
      top: 3em;
      right: 6em;
      width: 26em;
      padding: 3em;
      position: absolute;
    }
  }

  & .tetris {
    display: none;

    @media (min-width: 820px) {
      z-index: -1;
      display: block;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &.shape-1 {
      top: -8em;
      left: 1em;
    }
  }
`;

const Header = styled.h2`
  color: white;
  font-size: 9vw;
  padding: 0.5em 1em;
  text-transform: uppercase;
  background-color: var(--ked-blue);

  top: 0;
  position: relative;

  @media (max-width: 680px) {
    transform: none !important;
  }

  @media (min-width: 540px) {
    font-size: 2.8em;
  }

  @media (min-width: 680px) {
    right: 0;
    top: 0.5em;
    z-index: 1;
    position: absolute;
  }

  @media (min-width: 850px) {
    top: 0em;
    z-index: 1;
    right: unset;
    left: 1.5em;
    position: absolute;
  }

  @media (min-width: 1100px) {
    top: 1.5em;
    right: 1em;
    left: unset;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    transition: transform 0.2s ease;
  }
`;

const TeamSection = (props) => {
  const { header, image, children } = props;
  return (
    <Wrapper id="team" aria-labelledby="team-header">
      <img
        src={image}
        width="750px"
        height="710px"
        data-parallax="-0.2"
        data-parallax-start-offset="300"
      />
      <Header
        id="team-header"
        data-parallax="0.2"
        data-parallax-start-offset="500"
      >
        {header}
      </Header>
      <Html akcentColor="white" textColor="white" className="ked-html">
        {children}
      </Html>
      <LShape
        className="tetris shape-1"
        data-parallax="-0.2"
        size="7em"
        data-parallax-start-offset="800"
        rotate="180"
        color="var(--ked-gray)"
      />
    </Wrapper>
  );
};

TeamSection.displayName = "TeamSection";
TeamSection.propTypes = {};

TeamSection.defaultProps = {};

export default TeamSection;
