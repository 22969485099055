import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

// Api.
import api, { pages, useApi } from "api";

// I18n.
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "components/i18n";

// Hooks.
import useParallax from "hooks/use-parallax";

// Sections.
import Banner from "./sections/banner";
import ProjectsSection from "./sections/projects";
import ProductsSection from "./sections/products";
import AchievementsSection from "./sections/achievements";
import MissionSection from "./sections/mission";
import PhilosophySection from "./sections/philosophy";
import TeamSection from "./sections/team";

// Layout.
import { Stack } from "@ludekarts/base-ui/layout";

// Styled.
import { Wrapper, Header, Subline } from "./styled";
import Footer from "components/footer";
import FadeIn from "components/fade-in";

import ProjectCard from "components/cards/project";
import ProductCard from "components/cards/product";
import AchievementCard from "components/cards/achievement";

const Landing = () => {
  const container = useRef();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const lang = getCurrentLanguage();
  const [content] = useApi(api.getLandingContent, lang);

  useParallax(!!content);

  useEffect(() => {
    if (container.current) {
      // When navigation used after fadeIn.
      const target = document.querySelector(hash || "#none");
      target ? target.scrollIntoView(true) : window.scrollTo(0, 0);
    } else {
      // When navigation used before fadeIn (inital mount).
      setTimeout(() => {
        const target = document.querySelector(hash || "#none");
        target ? target.scrollIntoView(true) : window.scrollTo(0, 0);
      }, 400);
    }
  }, [hash]);

  return (
    <>
      <FadeIn trigger={!!content}>
        {content && (
          <>
            <Wrapper offsetTop="6rem" ref={container}>
              <Stack gap="1em" as="main">
                {/* <Banner source={content.banner} /> */}

                <Header id="start">{t("landing.headline")}</Header>

                <ProductsSection aria-label={t("sections.products")}>
                  {content.products.map((card) => (
                    <ProductCard key={card.id} product={card} />
                  ))}
                </ProductsSection>

                <Subline>{t("landing.tagline")}</Subline>

                <AchievementsSection header={t("sections.achievements")}>
                  {content.achievements.map((card) => (
                    <AchievementCard
                      key={card.id}
                      achievement={card}
                      role="listitem"
                    />
                  ))}
                </AchievementsSection>

                <ProjectsSection header={t("sections.projects")}>
                  {content.projects.map((card) => (
                    <ProjectCard key={card.id} project={card} role="listitem" />
                  ))}
                </ProjectsSection>

                <MissionSection header={t("sections.mission")}>
                  {content.mission.excerpt}
                </MissionSection>

                <PhilosophySection header={t("sections.philosophy")}>
                  {content.philosophy.excerpt}
                </PhilosophySection>

                <TeamSection
                  header={t("sections.team")}
                  image={content.team.cover}
                >
                  {content.team.content}
                </TeamSection>
              </Stack>
            </Wrapper>
            <Footer />
          </>
        )}
      </FadeIn>
    </>
  );
};

Landing.displayName = "Landing";
export default Landing;
