import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.svg`
  ${({ color, size }) => `
    width: ${size};
    height: ${size};
    fill: ${color};
  `}
`;

const Icon = props => {
  const { size, color, name } = props;
  return (
    <Wrapper size={size} color={color}>
      <use href={`assets/images/icons.svg#${name}`} />
    </Wrapper>
  );
}

Icon.displayName = "Icon";
Icon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: "1em",
  color: "inherit",
};

export default Icon;