import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import anime from "animejs";
import { SquareShape } from "components/tetris";
import { useTranslation } from "react-i18next";
import { debounce } from "@ludekarts/utility-belt";

// Layout.
import { Text } from "@ludekarts/base-ui";
import { Stack } from "@ludekarts/base-ui/layout";

const Wrapper = styled.div`
  width: 100%;
    
  & .grid {
    max-width: 250px;
    max-height: 220px;
    margin: 0 auto;
    gap: 0.2em;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(10, 1fr);  
    overflow: hidden;

    & .shape {
      position: relative;
      width: 1.2em;
      height: 1.2em;      
      
      &.color {        
        background-color: var(--ked-gray);   
      }
    }
  }
`;


const Slider = styled.input`
  -webkit-appearance: none;  
  appearance: none;
  width: 100%; 
  height: 15px;
  background: var(--ked-gray);
  outline: none; 
  opacity: 0.7;
  -webkit-transition: .2s; 
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

    &::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    width: 25px; 
    height: 25px; 
    background: var(--ked-red);
    cursor: pointer; 
  }

  &::-moz-range-thumb {
    width: 25px; 
    height: 25px; 
    background: var(--ked-red);
    cursor: pointer; 
  }
`;

const Input = styled.input`
  padding: 0;
  width: 100%;
  height: 1em;
  border: none;
  font-size: 1em;
  flex-shrink: 2;
  display: inline;
  line-height: 1em;
  background: none;
  text-align: right;;
  color: var(--ked-green);
  
  &:focus {
    outline: none;
  }
`;

const SliderGrid = styled.div`
  gap: 1em;
  display: grid;
  text-align: center;
  align-items: flex-end;
  grid-template-columns: 1fr;
 
  & > *:nth-child(2) {
    grid-row: 3/3;
  }

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
   
    & > *:nth-child(2) {
      grid-row: unset;
    }
    
    & > *:nth-child(3) {
      grid-column: 1/3;
    }
  }

  @media (min-width: 420px) {
    text-align: left;
  } 
`;

const Counter = styled.div`
  display: flex;
  font-size: 3em;
  font-weight: bold;
  align-items: center;
  color: var(--ked-blue);
  text-align: center;

  @media (min-width: 420px) {
    text-align: right;
  } 
`;

const heartData = [
  [0, 0, 1, 1, 0, 0, 0, 1, 1, 0, 0],
  [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
  [1, 1, 2, 2, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
  [0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0],
  [0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0],
  [0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
];


const HeartSlider = props => {
  const { maxValue, onUpdate } = props;
  const wrapper = useRef();
  const animation = useRef();
  const { t } = useTranslation();
  const [value, setValue] = useState(1);

  const onAmountUpdate = debounce(value => {
    onUpdate && onUpdate(value);
  }, 400);

  const updateHeart = event => {
    const value = Number(event.target.value);
    if (value > 0) {
      const slowdownRate = 0.09;
      animation.current.seek(animation.current.duration * (Math.log(value * slowdownRate) / Math.log(maxValue * slowdownRate)));
      setValue(value);
      onAmountUpdate(value);
    }
  };

  useEffect(() => {
    if (wrapper.current) {
      animation.current = anime({
        autoplay: false,
        translateY: [-270, 0],
        targets: ".shape > svg",
        easing: "cubicBezier(.42, -0.31, 0, 1)",
        delay: (el, i, length) => (length - i) * (i < 16 ? anime.random(20, 70) : anime.random(70, 120)),
      });
    }
    return () => animation.current = null;
  }, []);

  return (
    <Wrapper ref={wrapper}>
      <Stack gap="2em" className="--stretch">
        <div className="grid">
          {
            heartData.map((row, r) =>
              row.map((value, c) =>
                <SquareShape
                  size="1.2em"
                  key={`${r}-${c}`}
                  className={`shape ${value ? "color" : ""}`}
                  color={value ? "var(--ked-red)" : "transparent"} />
              )
            )
          }
        </div>
        <SliderGrid>
          <Text weight="bold" color="var(--ked-blue)" size="1.3em">{t("heart.slider-title")}</Text>
          <Stack gap="0.3em" className="--stack-end --text-right">
            <Counter>
              <Input name="amount" color="var(--ked-green)" value={value} min="1" onChange={updateHeart} type="number" />
              <span>PLN</span>
            </Counter>
            <Text color="var(--ked-text)" size="0.8em">{t("heart.slider-description")}</Text>
          </Stack>
          <Slider type="range" min="1" max={maxValue} onChange={updateHeart} value={value} />
        </SliderGrid>
      </Stack>

    </Wrapper>
  );
}

HeartSlider.displayName = "HeartSlider";
HeartSlider.propTypes = {
  maxValue: PropTypes.number,
};

HeartSlider.defaultProps = {
  maxValue: 100,
};

export default HeartSlider;
