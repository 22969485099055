import React, { useState } from "react";
import { Text } from "@ludekarts/base-ui";
import { Container, Stack, Space, Rail } from "@ludekarts/base-ui/layout";
import { getCurrentLanguage } from "components/i18n";
import { useTranslation, Trans } from "react-i18next";
import Html from "components/html";
import FadeIn from "components/fade-in";
import Footer from "components/footer";
import { Helmet } from "react-helmet";
import useParallax from "hooks/use-parallax";
import useMediaQuery from "hooks/use-media-query";
import { TShape, L2Shape, PlusShape, SquareShape } from "components/tetris";
import {
  Form,
  InteractiveInput,
  SubmitButton,
  RadioBox,
} from "components/form";
import Rodo from "components/rodo";
import Heart from "components/heart-slider";
import * as baseUi from "@ludekarts/base-ui";
import Prompter from "components/prompter";

import Icon from "components/icon";

// Api.
import api, { useApi } from "api";
import getReCaptchaToken from "helpers/recaptcha";

// Images.
import astroBoyPng from "assets/images/astro-boy.png";
import astroGirlPng from "assets/images/astro-girl.png";

// Styled.
import {
  Wrapper,
  Header,
  KedText,
  Boy,
  Girl,
  Greenscreen,
  TransactionButton,
  TransactionRail,
  ColorBlock,
  UaFlag,
} from "./styled";

const componentsMap = {
  span: <span />,
  em: <em />,
};

const Donate = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [amount, setCurrentAmout] = useState(1);
  const [lockForm, setLockForm] = useState(false);
  const [prompterContent, setPrompter] = useState();
  const [donate] = useApi(api.getDonateContent, lang);

  const isMobile = useMediaQuery("(max-width: 560px)");
  const isTablet = useMediaQuery("(max-width: 1150px)") && !isMobile;
  const isDesktop = !isMobile && !isTablet;

  useParallax(!!donate);

  const scrollTo = (event) => {
    const target = document.getElementById(event.target.dataset.target);
    target.scrollIntoView();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (lockForm) return;

    setLockForm(true);

    const userData = baseUi.Form.formInputsToObject(event.target);
    const { firstName, lastName, email, purpose } = userData;

    try {
      const rcToken = await getReCaptchaToken();
      const p24Token = await api.donate(
        firstName,
        lastName,
        email,
        amount * 100,
        purpose,
        rcToken
      ); // amount * 100 = złotówki na gosze.
      window.location.href = api.goToPayment(p24Token);
    } catch (error) {
      console.error(error);
      setPrompter({ error: error.message });
    }
  };

  return (
    <>
      <Helmet>
        <script src={api.connectReCaptcha()}></script>
      </Helmet>
      <FadeIn trigger={!!donate}>
        {donate && (
          <>
            <Container offsetTop="6rem">
              <Wrapper>
                <Header>
                  <Trans components={componentsMap}>donate.headline</Trans>
                </Header>

                {!isMobile ? null : (
                  <>
                    <Girl data-parallax="-0.2" data-parallax-mode="absolute">
                      <KedText>
                        <Trans components={componentsMap}>donate.subline</Trans>
                      </KedText>
                      <img src={astroGirlPng} />
                    </Girl>

                    <Greenscreen>
                      <Boy
                        data-parallax="-0.6"
                        data-parallax-mode="absolute"
                        data-parallax-stop="-360"
                      >
                        <img src={astroBoyPng} />
                      </Boy>
                      <Stack>
                        <Html as="section" textColor="white">
                          {donate.callToAction}
                        </Html>
                        <TransactionRail
                          gap="2em"
                          className="--rail-spread"
                          breakpoint="720px"
                        >
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="online-transfer"
                          >
                            {t("donate.online-transfer-btn")}
                          </TransactionButton>
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="classic-transfer"
                          >
                            {t("donate.classic-transfer-btn")}
                          </TransactionButton>
                        </TransactionRail>
                      </Stack>
                    </Greenscreen>

                    <TShape
                      className="tetris shape-1"
                      rotate="180"
                      color="var(--ked-orange)"
                      size="13em"
                      data-parallax="-0.8"
                      data-parallax-mode="absolute"
                    />
                    <PlusShape
                      className="tetris shape-3"
                      rotate="45"
                      color="var(--ked-violet)"
                      size="8em"
                      data-parallax="-0.5"
                      data-parallax-mode="absolute"
                    />
                  </>
                )}

                {!isTablet ? null : (
                  <>
                    <Girl data-parallax="-0.2" data-parallax-mode="absolute">
                      <KedText>
                        <Trans components={componentsMap}>donate.subline</Trans>
                      </KedText>
                      <img src={astroGirlPng} />
                    </Girl>

                    <Boy data-parallax="-0.3" data-parallax-mode="absolute">
                      <img src={astroBoyPng} />
                    </Boy>

                    <TShape
                      className="tetris shape-1"
                      rotate="180"
                      color="var(--ked-orange)"
                      size="13em"
                      data-parallax="-0.8"
                      data-parallax-mode="absolute"
                    />
                    <L2Shape
                      className="tetris shape-2"
                      rotate="225"
                      color="var(--ked-blue)"
                      size="7em"
                      data-parallax="0.5"
                      data-parallax-mode="absolute"
                      onClick={scrollTo}
                      data-target="cta-anchor"
                    />
                    <PlusShape
                      className="tetris shape-3"
                      rotate="45"
                      color="var(--ked-violet)"
                      size="8em"
                      data-parallax="-0.5"
                      data-parallax-mode="absolute"
                    />
                    <TShape
                      className="tetris shape-5"
                      color="var(--ked-red)"
                      size="11em"
                      data-parallax="-1.1"
                      data-parallax-mode="absolute"
                    />

                    <Greenscreen>
                      <Stack>
                        <Html as="section" textColor="white">
                          {donate.content}
                        </Html>
                        <TransactionRail
                          gap="2em"
                          className="--rail-spread"
                          breakpoint="720px"
                        >
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="online-transfer"
                          >
                            {t("donate.online-transfer-btn")}
                          </TransactionButton>
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="classic-transfer"
                          >
                            {t("donate.classic-transfer-btn")}
                          </TransactionButton>
                        </TransactionRail>
                      </Stack>
                    </Greenscreen>
                  </>
                )}

                {!isDesktop ? null : (
                  <>
                    <Girl data-parallax="-0.2" data-parallax-mode="absolute">
                      <KedText>
                        <Trans components={componentsMap}>donate.subline</Trans>
                      </KedText>
                      <img src={astroGirlPng} />
                    </Girl>

                    <Boy data-parallax="-0.3" data-parallax-mode="absolute">
                      <img src={astroBoyPng} />
                    </Boy>

                    <TShape
                      className="tetris shape-1"
                      rotate="180"
                      color="var(--ked-orange)"
                      size="13em"
                      data-parallax="-0.8"
                      data-parallax-mode="absolute"
                    />
                    <L2Shape
                      className="tetris shape-2"
                      rotate="225"
                      color="var(--ked-blue)"
                      size="7em"
                      data-parallax="0.5"
                      data-parallax-mode="absolute"
                      onClick={scrollTo}
                      data-target="cta-anchor"
                    />
                    <PlusShape
                      className="tetris shape-3"
                      rotate="45"
                      color="var(--ked-violet)"
                      size="8em"
                      data-parallax="-0.5"
                      data-parallax-mode="absolute"
                    />
                    <TShape
                      className="tetris shape-5"
                      color="var(--ked-red)"
                      size="11em"
                      data-parallax="-1.1"
                      data-parallax-mode="absolute"
                    />

                    <Space space="65em 0" className="invisible" />

                    <Greenscreen data-parallax="0.7">
                      <Stack>
                        <Html as="section" textColor="white">
                          {donate.content}
                        </Html>
                        <TransactionRail gap="2em" className="--rail-spread">
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="online-transfer"
                          >
                            {t("donate.online-transfer-btn")}
                          </TransactionButton>
                          <TransactionButton
                            className="lift-panel"
                            onClick={scrollTo}
                            data-target="classic-transfer"
                          >
                            {t("donate.classic-transfer-btn")}
                          </TransactionButton>
                        </TransactionRail>
                      </Stack>
                    </Greenscreen>
                  </>
                )}

                <Stack gap="3.5em" id="cta-anchor">
                  <Text id="online-transfer" as="h2">
                    {t("donate.online-transfer-header")}
                  </Text>
                  <Form onSubmit={handleSubmit} className="donate">
                    <Stack gap="2em">
                      <Rail gap="3em" breakpoint="980px">
                        <Heart
                          maxValue={donate.maxTransferValue}
                          onUpdate={setCurrentAmout}
                        />

                        <Stack gap="2em" className="user-data">
                          <InteractiveInput data-label={t("form.first-name")}>
                            <input
                              id="firstName"
                              type="text"
                              name="firstName"
                              required
                              placeholder={t("form.first-name")}
                            />
                          </InteractiveInput>

                          <InteractiveInput data-label={t("form.last-name")}>
                            <input
                              type="text"
                              name="lastName"
                              placeholder={t("form.last-name")}
                            />
                          </InteractiveInput>

                          <InteractiveInput data-label={t("form.email")}>
                            <input
                              type="email"
                              name="email"
                              required
                              placeholder={t("form.email")}
                            />
                          </InteractiveInput>

                          <Rodo color="var(--ked-blue)" />
                        </Stack>
                      </Rail>

                      <Rail gap="3em" breakpoint="1240px">
                        <Stack gap="0.8em" className="radio-rail">
                          <Text
                            color="var(--ked-blue)"
                            weight="600"
                            size="1.2em"
                          >
                            {t("donate.target-header")}
                          </Text>
                          <Rail
                            className="--rail-spread --expand-content"
                            breakpoint="940px"
                          >
                            <RadioBox
                              id="ked-radio"
                              name="purpose"
                              value="Napisy po ukraińsku"
                              defaultChecked
                            >
                              <Rail
                                gap="0.5em"
                                className="--v-center --h-center"
                              >
                                {/* <Icon name="ked" size="1.5em" /> */}
                                <UaFlag />
                                <span>{t("donate.target-statute-goals")}</span>
                              </Rail>
                            </RadioBox>
                            <RadioBox
                              id="pie-radio"
                              name="purpose"
                              value="Pi-stacja"
                            >
                              <Rail
                                gap="0.5em"
                                className="--v-center --h-center"
                              >
                                <Icon name="pie" size="1.5em" />
                                <span>{t("donate.target-pi-stacja")}</span>
                              </Rail>
                            </RadioBox>
                            <RadioBox
                              id="cm-radio"
                              name="purpose"
                              value="Mapa Karier"
                            >
                              <Rail
                                gap="0.5em"
                                className="--v-center --h-center"
                              >
                                <Icon name="cm" size="1.5em" />
                                <span>{t("donate.target-career-map")}</span>
                              </Rail>
                            </RadioBox>
                          </Rail>
                        </Stack>
                        <SubmitButton type="submit" className="lift-panel">
                          <Rail gap="1.5em" className="--v-center --h-center">
                            <span>{t("donate.submit-button")}</span>
                            <Icon name="arrow" size="1.5em" color="white" />
                          </Rail>
                        </SubmitButton>
                      </Rail>
                    </Stack>
                  </Form>
                </Stack>

                <Space space="3em" />
                <h2 id="classic-transfer">
                  {t("donate.classic-transfer-header")}
                </h2>

                <ColorBlock color="var(--ked-violet)">
                  <Html as="section" textColor="white">
                    {donate.transferData}
                  </Html>
                  <L2Shape
                    className="top-right"
                    rotate="90"
                    color="white"
                    size="10em"
                  />
                  <SquareShape
                    className="bottom-left"
                    color="white"
                    size="3em"
                  />
                </ColorBlock>

                <ColorBlock color="var(--ked-lite-blue)">
                  <Html as="section" textColor="white">
                    {donate.transferInfo}
                  </Html>
                  <TShape className="bottom-right" color="white" size="9em" />
                </ColorBlock>
              </Wrapper>
            </Container>
            <Space space="3em" />
            <Footer />
          </>
        )}
      </FadeIn>
      <Prompter
        source={prompterContent}
        onClose={() => setPrompter(undefined)}
      />
    </>
  );
};

Donate.displayName = "Donate";
Donate.propTypes = {};

Donate.defaultProps = {};

export default Donate;
