import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text } from "@ludekarts/base-ui";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Markdown from "components/markdown";

// Api.
import api, { useApi } from "api";

// Layout.
import { Container, Stack, Space, Rail } from "@ludekarts/base-ui/layout";

const Card = styled.section`
  padding: 3.5em 2.5em 2.5em;
  color: white;
  max-width: 41rem;
  line-height: 1.4em;
  position: relative;
  background-color: var(--ked-blue);

  * .ked-markdown {
    h3 {
      line-height: 1.3em;
    }
  }

  &::after {
    top: 0;
    right: 0;
    width: 1.7em;
    display: flex;
    content: "❤";
    position: absolute;
    background-color: white;
    color: var(--ked-red);
    font-size: 4em;
    height: 1.5em;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 700px) {
    margin-top: 3em;
    &::after {
      left: 0;
      bottom: 100%;
      top: unset;
      right: unset;
      background-color: var(--ked-blue);
    }
  }
`;

const Thanks = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [thanks] = useApi(api.getThanksContent, lang);
  return (
    <FadeIn trigger={!!thanks}>
      {
        thanks && <>
          <Container offsetTop="10em">
            <Stack className="--stack-center">
              <Card>
                <Stack gap="2em">
                  <Text size="2em" weight="800" color="white" lh="1.3em">{thanks.title}</Text>
                  <Markdown textColor="white" akcentColor="rgba(255, 255, 255, 0.5)">{thanks.content}</Markdown>
                </Stack>
              </Card>
            </Stack>
            <Rail className="--rail-center --space-2x">
              <Link to="/">{t("thanks.goback-link")}</Link>
            </Rail>
            <Space space="2em" />
          </Container>
          <Footer />
        </>
      }
    </FadeIn >
  );
}

Thanks.displayName = "Thanks";
Thanks.propTypes = {};

Thanks.defaultProps = {};

export default Thanks;




