import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "@ludekarts/base-ui";
import { LShape, PlusShape } from "components/tetris";


const Wrapper = styled.section`
  margin-top: 0;
  position: relative;
  scroll-margin-top: 5em;
  
  @media (min-width: 460px) {
    margin-top: 2em;
  }

  & h2 {
    font-size: 9vw;
    @media (min-width: 460px) {
      font-size: 2.8em;
    }
  }

  & > .tetris {
    display: none;   

    @media (min-width: 750px) {
      z-index: -1;
      display: block;
      position: absolute;
      transition: tarsnform .2s ease;
    }

    &.shape-1 {
      top: 8em;
      left: 35%;
    }
   
    &.shape-2 {
      top: 13em;
      right: 2em;
    }
  }
`;

const Grid = styled.div`
  gap: 2em;
  display: grid;
  margin-top: 2em;
  align-items: flex-start;
  grid-template-columns: 1fr;

  & > div {
    font-size: 1.2em;
  }

  @media (min-width: 750px) {
    align-items: stretch;
    grid-template-columns: 1fr 1fr;

    & > div {
      font-size: 1.5em;      
    }
  }

  @media (min-width: 1100px) {
    gap: 2em 5em;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;

    & > div:nth-child(odd) {
      margin-top: 2em;
    }
    
  }
  
`;

const AchievementsSection = props => {
  const { header, children } = props;
  return (
    <Wrapper id="achievements" aria-labelledby="achievements-header">
      <Text id="achievements-header" as="h2" weight="800" transform="uppercase" >{header}</Text>
      <Grid role="list">{children}</Grid>
      <PlusShape className="tetris shape-1" data-parallax="0.4" data-parallax-mode="absolute" size="4em" color="var(--ked-violet)" />
      <LShape className="tetris shape-2" data-parallax="0.2" data-parallax-mode="absolute" size="4em" color="var(--ked-gray)" size="8em" />
    </Wrapper>
  );
}

AchievementsSection.displayName = "AchievementsSection";
AchievementsSection.propTypes = {
  header: PropTypes.string,
};

AchievementsSection.defaultProps = {};

export default AchievementsSection;

