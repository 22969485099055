import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, useParams, Redirect } from "react-router-dom";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container, Stack } from "@ludekarts/base-ui/layout";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Html from "components/html";
import ArrowButton from "components/arrow-button";
import { LShape, TShape, PlusShape, SquareShape } from "components/tetris";

const Wrapper = styled.div`
  gap: 1em;
  display: grid;
  padding-bottom: 3em;
  grid-template-columns: 1fr;

  @media (min-width: 750px) {
    gap: 3em;
    grid-template-columns: 23em 1fr;
  }

  & .ked-html {
    min-height: 35rem;
  }
`;

const Logo = styled.div`
  padding: 3em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
`;

const Blocks = styled.div`
  width: 23em;
  position: relative;

  @media (max-width: 750px) {
    width: 100%;

    & .shape {
      display: none;
    }

    & .arrow-button {
      width: 4em;
      height: 4em;
      top: -0.5em;
      left: -0.5em;
      position: absolute;
      display: inline-block;
      border: 4px solid white;
    }
  }

  & .shape:nth-child(2) {
    margin-top: 1em;
  }

  & .shape:nth-child(3) {
    position: absolute;
    top: 15em;
    right: 4em;
  }

  & .shape:nth-child(4) {
    position: absolute;
    left: 19.7em;
    top: 13em;
  }

  & .shape:nth-child(5) {
    position: absolute;
    top: 22em;
    left: 0em;
  }

  & .shape:nth-child(6) {
    position: absolute;
    top: 27em;
    left: 9em;
  }
`;

const colors = {
  openstax: "var(--osp-color)",
  "pi-stacja": "var(--pie-color)",
  "mapa-karier": "var(--cm-color)",
};

const Project = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [project] = useApi(api.getProject, slug, lang);
  const mainColor = project ? colors[project.uid] : "var(--ked-gray)";

  return project === null ? (
    <Redirect to="/404" />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{!project ? "..." : project.title}</title>
      </Helmet>
      <FadeIn trigger={!!project}>
        {project && (
          <>
            <Container offsetTop="6rem">
              <Stack>
                <h1>{project.title}</h1>
                <Wrapper>
                  <Blocks>
                    <Logo color={mainColor}>
                      <img src={project.cover} />
                    </Logo>
                    <ArrowButton
                      className="shape lift-panel arrow-button"
                      color={mainColor}
                      as={Link}
                      to="/"
                      size="5.5em"
                      left
                    />
                    <LShape
                      className="shape"
                      color={mainColor}
                      size="6.65em"
                      rotate={0}
                    />
                    <SquareShape
                      className="shape"
                      color={mainColor}
                      size="3.3em"
                    />
                    <TShape className="shape" color={mainColor} size="10em" />
                    <PlusShape
                      className="shape"
                      color={mainColor}
                      size="10em"
                    />
                  </Blocks>
                  <Html akcentColor={mainColor} className="ked-html">
                    {project.content}
                  </Html>
                </Wrapper>
              </Stack>
            </Container>
            <Footer />
          </>
        )}
      </FadeIn>
    </>
  );
};

Project.displayName = "Project";
Project.propTypes = {};

Project.defaultProps = {};

export default Project;
