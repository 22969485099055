import React from "react";
import Html from "components/html";
import styled from "styled-components";
import { Stack, Rail } from "@ludekarts/base-ui/layout";

const Wrapper = styled.div`
  width: 100%;
  padding: 1em;
  min-height: 10em;
  font-size: 1.5em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Colors */

  &:nth-of-type(1) {
    color: white;
    background-color: var(--ked-blue);
    & > img {
      margin-left: auto;
    }
  }

  &:nth-of-type(2) {
    text-align: right;
    color: white;
    background-color: var(--ked-red);
    & > img {
      margin-right: auto;
    }
  }

  &:nth-of-type(3) {
    color: var(--ked-text);
    background-color: var(--ked-orange);
    & > img {
      margin-left: auto;
    }
  }

  &:nth-of-type(4) {
    text-align: right;
    color: var(--ked-text);
    background-color: var(--ked-green);
    & > img {
      margin-right: auto;
    }
  }

  @media (max-width: 750px) {
    text-align: left !important;
    & > img {
      margin-right: auto;
      margin-left: 0 !important;
    }
  }

  /* Warn against H1 & H2 headers */
  & h1,
  & h2 {
    color: red;
    font-size: 1em;
    text-decoration: line-through;
  }

  & h3 {
    font-size: 2em;
    color: currentColor;
  }

  & p {
    margin-top: 0;
    font-weight: 800;
    line-height: 1.4em;
    text-transform: uppercase;
  }

  @media (max-width: 750px) {
    text-align: left !important;
  }

  & img {
    max-height: 36px;
    width: auto;
  }
`;

const AchievementCard = (props) => {
  const { achievement, ...rest } = props;
  return (
    <Wrapper {...rest} className="lift-card">
      <div>
        <h3>{achievement.title}</h3>
        <Html>{achievement.content}</Html>
      </div>
      <img src={achievement.cover} />
    </Wrapper>
  );
};

export default AchievementCard;

// ---- Helpers ----------------

function renderInPosition(source) {
  const { textPosition, image, content, textColor } = source;

  if (textPosition === "TopLeft") {
    return (
      <Stack gap="0">
        <Html textColor={textColor}>{content}</Html>
        <Rail className="push-down --h-end">
          <img src={image[0].url} />
        </Rail>
      </Stack>
    );
  } else if (textPosition === "TopRight") {
    return (
      <Stack gap="0">
        <Html textColor={textColor} textAlign="right">
          {content}
        </Html>
        <Rail className="push-down --h-start">
          <img src={image[0].url} />
        </Rail>
      </Stack>
    );
  } else if (textPosition === "BottomLeft") {
    return (
      <Stack gap="0">
        <Rail className="push-up --h-end">
          <img src={image[0].url} />
        </Rail>
        <Html textColor={textColor}>{content}</Html>
      </Stack>
    );
  } else if (textPosition === "BottomRight") {
    return (
      <Stack gap="0">
        <Rail className="push-up --h-satrt">
          <img src={image[0].url} />
        </Rail>
        <Html textColor={textColor} textAlign="right">
          {content}
        </Html>
      </Stack>
    );
  } else {
    return null;
  }
}
