import React from "react";
import styled from "styled-components";
import { changeLanguage, getCurrentLanguage } from "components/i18n";

const Toggle = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  font-size: 0.8em;

  & > button {
    width: 30px;
    height: 30px;

    &.active {
      color: white;
      background-color: var(--ked-blue);
    }
  }
`;

const LanguaneToggle = () => {
  const currentLanguane = getCurrentLanguage();
  return (
    <Toggle>
      <button className={currentLanguane === "pl" ? "active" : ""} onClick={() => changeLanguage("pl")}>PL</button>
      <button className={currentLanguane === "en" ? "active" : ""} onClick={() => changeLanguage("en")}>EN</button>
    </Toggle>
  );
}

LanguaneToggle.displayName = "LanguaneToggle";
LanguaneToggle.propTypes = {};

LanguaneToggle.defaultProps = {};

export default LanguaneToggle;