import React from "react";
import styled from "styled-components";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container } from "@ludekarts/base-ui/layout";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Html from "components/html";

const Wrapper = styled.div`
  gap: 3em;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 860px) {
    grid-template-columns: 31em 1fr;
  }
`;

const Bricks = styled.div`
  gap: 1em;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 5em) 1fr;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 860px) {
    grid-row: unset;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 5em repeat(3, 7em);
    grid-template-areas:
      "space space space space"
      "one-p one-p one-p box1"
      "logo box2 photos photos "
      "press press press press";
  }
`;

const Brick = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  ${({ color = "transparent", area }) => `
    background-color: ${color};
    @media (min-width: 860px) {
      grid-area: ${area};
    }
  `}

  &.empty {
    display: none;
    @media (min-width: 860px) {
      display: flex;
    }
  }

  &:after {
    left: 0.6em;
    bottom: 0.6em;
    color: white;
    font-size: 1.5em;
    font-weight: 700;
    position: absolute;
    pointer-events: none;
    content: attr(data-text);
  }

  & a {
    opacity: 0;
  }
`;

const Media = (props) => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [media] = useApi(api.getMediaContent, lang);
  return (
    <FadeIn trigger={!!media}>
      {media && (
        <>
          <Container offsetTop="6rem">
            <h1>{media.title}</h1>
            <Wrapper>
              <Bricks>
                <Brick area="space" className="empty" />
                <Brick
                  area="one-p"
                  color="var(--ked-lite-blue)"
                  data-text={t("media.flyer")}
                  className="lift-panel"
                >
                  <a
                    className="--cover-link"
                    href={`${media.flyer}`}
                    target="_blank"
                  >
                    {t("media.flyer")}
                  </a>
                </Brick>
                <Brick
                  area="box1"
                  color="var(--ked-yellow)"
                  className="empty"
                />
                <Brick
                  area="logo"
                  color="var(--ked-orange)"
                  className="empty"
                />
                <Brick
                  area="photos"
                  color="var(--ked-blue)"
                  data-text={t("media.images")}
                  className="lift-panel"
                >
                  <a
                    className="--cover-link"
                    href={`${media.images}`}
                    target="_blank"
                  >
                    {t("media.images")}
                  </a>
                </Brick>
                <Brick area="box2" color="var(--ked-green)" className="empty" />
                <Brick
                  area="press"
                  color="var(--ked-violet)"
                  data-text={t("media.press-pack")}
                  className="lift-panel"
                >
                  <a
                    className="--cover-link"
                    href={`${media.presspack}`}
                    target="_blank"
                  >
                    {t("media.press-pack")}
                  </a>
                </Brick>
              </Bricks>
              <Html as="section" akcentColor="var(--ked-yellow)">
                {media.content}
              </Html>
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Media.displayName = "Media";
Media.propTypes = {};

Media.defaultProps = {};

export default Media;
