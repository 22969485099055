import React from "react";
import styled, { keyframes } from "styled-components";

// Api.
import api, { useApi } from "api";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container, Stack } from "@ludekarts/base-ui/layout";

// Components.
import Footer from "components/footer";
import FadeIn from "components/fade-in";

const scaleUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-3em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  position: relative;

  color: var(--ked-blue);
  grid-template-columns: 1fr;

  @media (min-width: 970px) {
    grid-template-columns: 1fr 38rem;
  }
`;

const Mosaic = styled.div`
  display: grid;
  margin: 1em auto 0;
  grid-template-columns: repeat(13, 5vw);
  grid-template-rows: repeat(10, 5vw);

  @media (min-width: 970px) {
    margin: 0;
    grid-template-columns: repeat(13, 3em);
    grid-template-rows: repeat(10, 3em);
  }

  grid-template-areas:
    ". . . . a . . . . . . . ."
    ". . . . b b . . . . . . ."
    ". . d . . . . . . . j k ."
    ". . . . . e e e . . j . ."
    ". . . . . . f . . . j . ."
    ". . . g g . . . . . . . ."
    ". . h h . . . . i . . . ."
    ". . . . . . . . . . . n ."
    ". . . . . l l . . . m n .";

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    animation: ${scaleUp} 1.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    background-color: var(--ked-lite-gray);

    &:nth-of-type(1) {
      grid-area: a;
      animation-delay: 0.3s;
      background-color: var(--ked-red);
    }

    &:nth-of-type(2) {
      grid-area: b;
      animation-delay: 0.3s;
      background-color: var(--ked-red);
    }

    &:nth-of-type(3) {
      grid-area: c;
    }

    &:nth-of-type(4) {
      grid-area: d;
    }

    &:nth-of-type(5) {
      grid-area: e;
      animation-delay: 0.2s;
    }

    &:nth-of-type(6) {
      grid-area: f;
      animation-delay: 0.2s;
    }

    &:nth-of-type(7) {
      grid-area: g;
      animation-delay: 0.6s;
      background-color: var(--ked-orange);
    }

    &:nth-of-type(8) {
      grid-area: h;
      animation-delay: 0.6s;
      background-color: var(--ked-orange);
    }

    &:nth-of-type(9) {
      grid-area: i;
      animation-duration: 1.4s;
    }

    &:nth-of-type(10) {
      grid-area: j;
      animation-delay: 0.5s;
      background-color: var(--ked-violet);
    }

    &:nth-of-type(11) {
      grid-area: k;
      animation-delay: 0.5s;
      background-color: var(--ked-violet);
    }

    &:nth-of-type(12) {
      grid-area: l;
      background-color: var(--ked-lite-blue);
    }

    &:nth-of-type(13) {
      grid-area: m;
    }

    &:nth-of-type(14) {
      grid-area: n;
    }
  }
`;

const DownloadButton = styled.a`
  color: white;
  display: flex;
  padding: 1em 2em;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ked-blue);
`;

const DownloadIcon = () => (
  <svg viewBox="0 0 24 24" fill="white" width="2em">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z" />
  </svg>
);

const Documents = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [documents] = useApi(api.getDocumentsContent);

  return (
    <FadeIn trigger={!!documents}>
      {documents && (
        <>
          <Container offsetTop="6rem">
            <Wrapper>
              <Stack>
                <h1>{t("documents.headline")}</h1>
                {documents.map((doc) => (
                  <DownloadButton
                    key={doc.id}
                    href={doc.url}
                    target="_blank"
                    className="lift-panel"
                  >
                    <span>
                      <strong>{t("documents.download")}</strong>{" "}
                      <em>{doc[`title_${lang}`]}</em>
                    </span>
                    <DownloadIcon />
                  </DownloadButton>
                ))}
              </Stack>
              <Mosaic>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </Mosaic>
            </Wrapper>
          </Container>
          <Footer />
        </>
      )}
    </FadeIn>
  );
};

Documents.displayName = "Documents";
export default Documents;

// ---- Helpers ----------------

function dehyphenate(word) {
  return word.replace(/_+/g, " ");
}
