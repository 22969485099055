import React, { useRef } from "react";
import styled from "styled-components";
import { Text } from "@ludekarts/base-ui";
import { useTranslation } from "react-i18next";
import { clipboard } from "@ludekarts/utility-belt";
import { Link } from "react-router-dom";

import oppDonateSvg from "assets/images/opp-donate.svg";

const Wrapper = styled.span`

 --opp-color: #e96b04;

  display:flex;
  font-size: 1rem;
  position: relative;
  align-items: center;
  background-color: none;
  justify-content: center;

  @media (max-width: 73rem) {
    margin: 1rem auto;
  }

  & > img {
    height: 25px;
    min-width: 60px;
    pointer-events: none;
  }


  &:hover > .popup,
  &:focus-within > .popup {
    opacity: 1;
    pointer-events: all;
    transform: translate3d(0,0,0);

    @media (max-width: 520px) {
      transform: translate3d(-50%, 0, 0);
    }
  }
`;

const Popup = styled.div`
  top: 150%;
  width: 280px;
  right: -0.3em;
  position: absolute;
  border-radius: 0.3em;
  background-color: white;
  box-shadow: var(--bui-box-shadow);

  display: flex;
  flex-direction: column;

  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, -0.5em, 0);
  transition: opacity .3s ease, transform .3s ease;

  @media (max-width: 1100px) {
    left: -0.3em;
    right: unset;
  }

  @media (max-width: 520px) {
    left: 50%;
    top: unset;
    right: unset;
    bottom: 1em;
    position: fixed;
    transform: translate3d(-50%, 0.5em, 0);
  }

  &::before {
    width: 100%;
    content: "";
    height: 1em;
    bottom: 100%;
    position: absolute;
  }

  & > span {
    padding: 0.8em 1em;
  }

  & > span:first-child {
    color: white;
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;
    background-color: var(--opp-color);
    border-radius: 0.3em 0.3em 0em 0em;

    & > a.cta {
      font-weight: 900;
      padding: 0.2em;
      color: white;
      font-size: 0.9em;
      margin-top: 0.5em;
      min-height: unset;
      text-align: center;
      transition: filter .3s ease;
      background-color: var(--ked-blue);

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  & > span.opp-krs {
    display: flex;
    align-items: center;
    color: var(--opp-color);
    transition: color .3s ease;
    justify-content: space-between;
    border-radius: 0em 0em 0.3em 0.3em;
    border: 1px solid var(--ked-lite-gray);

    &.success {
      color: var(--ked-green);
      & button {
        color: white;
        background-color: var(--ked-green);
      }
    }

    & button {
      font-size: 0.6em;
      color: var(--opp-color);
      text-transform: uppercase;
      transition: background-color .3s ease, color .3s ease;
    }
  }
`;


const copy = clipboard();

const OppButton = () => {

  const copyButton = useRef();
  const { t } = useTranslation();

  const copyKRS = (event) => {
    if (copy("0000558853")) {
      event.target.parentNode.classList.add("success");
      event.target.textContent = t("opp.copied");
      setTimeout(() => {
        event.target.parentNode.classList.remove("success");
        event.target.textContent = t("opp.copy");
      }, 1200);
    }
  };

  return (
    <Wrapper tabindex="0" role="button" aria-pressed="false" >
      <img src={oppDonateSvg} />
      <Popup className="popup">
        <span>
          <span>{t("opp.info")}</span>
          <Link to="donate" className="cta">{t("opp.cta")}</Link>
        </span>
        <span className="opp-krs">
          <Text ellypsis>KRS: 0000558853</Text>
          <button ref={copyButton} onClick={copyKRS}>{t("opp.copy")}</button>
        </span>
      </Popup>
    </Wrapper>
  );
}

OppButton.displayName = "OppButton";
OppButton.propTypes = {};

OppButton.defaultProps = {};

export default OppButton;