import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ color, size }) => `
    width: ${size};
    height: ${size};
    background-color: ${color};
  `}  
`;

export const ArrowIcon = ({ color = "#1C1C1C", size = "1em", rotate, ...props }) => (
  <svg {...props} width={size} viewBox="0 0 24 24" aria-hidden="true" fill={color} transform={`rotate(${rotate})`}>
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
  </svg>
)

const ArrowButton = props => {
  const { text, size, color, left, ...rest } = props;
  return (
    <Wrapper {...rest} size={size} color={color}>
      <ArrowIcon size="100%" color={text} rotate={left ? 180 : 0} />
    </Wrapper>
  );
}

ArrowButton.displayName = "ArrowButton";
ArrowButton.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};

ArrowButton.defaultProps = {
  size: "6em",
  text: "white",
  color: "#CCCCCC",
};

export default ArrowButton;