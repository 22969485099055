import React from "react";
import Html from "components/html";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text } from "@ludekarts/base-ui";
import { PlusShape } from "components/tetris";
import { Stack } from "@ludekarts/base-ui/layout";
import ArrowButton from "components/arrow-button";

const Wrapper = styled.section`
  color: white;
  width: 100%;
  max-width: 52rem;
  position: relative;
  margin: 4em auto 0;
  padding: 2em 2em 3.5em;
  scroll-margin-top: 6em;
  background-color: var(--ked-green);

  @media (min-width: 750px) {
    margin: 10em auto 0;
    padding: 5em 4em;
  }

  & p {
    color: white;
    max-width: 44ch;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    text-align: center;

    @media (min-width: 750px) {
      font-size: 1.2em;
    }
  }

  & a.arrow-button {
    bottom: -2em;
    position: absolute;
  }

  & > .tetris {
    display: none;

    @media (min-width: 1100px) {
      z-index: -1;
      display: block;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &.shape-1 {
      top: -5em;
      left: 3em;

      & svg {
        width: 7em;
      }

      @media (min-width: 750px) {
        top: -10em;
        left: 30%;

        & svg {
          width: initial;
        }
      }
    }
  }
`;

const PhilosophySection = (props) => {
  const { header, children } = props;
  return (
    <Wrapper id="philosophy" aria-labelledby="philosophy-header">
      <Stack className="--stack-center">
        <Text
          id="philosophy-header"
          as="h2"
          size="2.8em"
          lh="1em"
          weight="800"
          color="white"
          transform="uppercase"
        >
          {header}
        </Text>
        <Html akcentColor="white" textColor="white">
          {children}
        </Html>
        <ArrowButton
          className="arrow-button lift-panel"
          color="var(--ked-blue)"
          as={Link}
          to="/philosophy"
          size="5em"
        />
      </Stack>
      <PlusShape
        className="tetris shape-1"
        data-parallax="-0.5"
        data-parallax-start-offset="600"
        rotate="45"
        color="var(--ked-violet)"
        size="10em"
      />
    </Wrapper>
  );
};

PhilosophySection.displayName = "PhilosophySection";
PhilosophySection.propTypes = {};

PhilosophySection.defaultProps = {};

export default PhilosophySection;
