import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@ludekarts/base-ui/panels";
import { Rail } from "@ludekarts/base-ui/layout";
import LanguaneToggle from "components/i18n/toggle";
import Hamburger from "components/hamburger";
import OppButton from "components/opp-button";

// Images.
import kedLogoSvg from "assets/images/ked-logo.svg";

const Wrapper = styled.nav`
  top: 0;
  left: 0;
  right: 0;  
  z-index: 1000;
  display: flex;
  position: fixed;
  font-weight: 600;
  padding-right: 1em;
  background-color: white;
  justify-content: space-between;
  
  & .hamburger {
    display: none;
  }

  @media(max-width: 1100px) {   
    
    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0.5em;
      content: "";
      z-index: 1000;    
      position: absolute;
      background-color: white;
    }
    
    & .buttons-container {
      width: 100%;
      position: fixed;
      z-index: 900;
      background: #f7f7f7;
      flex-direction: column;
      box-shadow: var(--bui-lift-shadow);
      top: ${({ open }) => open ? "4.5em" : "-100%"}; 
      transition: top .5s ease-out;
      
      & > *:not(:last-child) {   
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 2px solid var(--ked-lite-gray);

        &::before {
          width: 12em;
        }

        &.active::before  {
          bottom: 1.1em;
        }
      }
    }

    & .hamburger {
      display: block;
      z-index: 1000;
    }
  }

  & a {    
    padding: 1em;
    color: var(--ked-blue);
  }

  & a.nav-link:nth-child(2)::before {
    background-color: var(--ked-orange);
  }

  & a.nav-link:nth-child(3)::before {
    background-color: var(--ked-yellow);
  }

  & a.nav-link:nth-child(4)::before {
    background-color: var(--ked-green);
  } 

  & a.nav-link:nth-child(5)::before {
    background-color: var(--ked-violet);
  }

  & a.nav-link:nth-child(6)::before {
    background-color: var(--ked-lite-blue);
  }

`;

const Logo = styled.img`
  width: auto;
  height: 2.8em;
  z-index: 1000;
  position: relative;
`;

const NavigationLink = styled(NavLink)`
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;

  &::before {
    z-index: -1;
    left: 50%;
    opacity: 0;
    width: 100%;
    content: "";
    height: 0.8em;
    bottom: 0;
    position: absolute;    
    transform: translateX(-50%);
    transition: opacity .3s ease, bottom .3s ease, height .3s ease;
  }

  &:hover::before  {
    opacity: 1;
  }

  &.active::before {
    opacity: 1;
    bottom: 1.8em;
    height: 1.2em;
  }

  &.active.white {
    color: white;
  }
`;


const DropButton = styled.button`
  margin: 0px;  
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1em;
  line-height: inherit;  
  font-family: inherit;
  text-decoration: none;
  -webkit-box-pack: center;
  background: transparent;
  appearance: none;
  
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  
  height: 100%;
  padding: 1em;
  color: var(--ked-blue);

  & > * {
    pointer-events: none;
  }

  & > span {
    white-space: nowrap;
  }

  & > svg {
    width: 0.8em;
    margin-left: 0.5em;
    fill: var(--ked-blue);
  }
  
  &::before {
    z-index: -1;
    left: 50%;
    opacity: 0;
    width: 100%;
    content: "";
    height: 0.8em;
    bottom: 0;
    position: absolute;    
    transform: translateX(-50%);
    background-color: var(--ked-red);
    transition: opacity .3s ease, bottom .3s ease, height .3s ease;
  }

  &:hover::before  {
    opacity: 1;
  }

  @media(max-width: 1100px) {  
    width: 12em;
  }

  ${({ active }) => !active ? `` : `
    color: white;

    & > svg {      
      fill: white;
    }
    
    &::before {
      opacity: 1;    
      bottom: 1.8em;
      height: 1.2em;

      @media(max-width: 1100px) {  
        bottom: 1.1em;
      }
    }
  `}
`;

const DropContainer = styled.span`
  height: 100%;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
`;

const DropList = styled(Dropdown)`
  top: 105%;
  z-index: 300;
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--ked-blue);
  box-shadow: 0px 19px 19px -13px rgba(0,0,0,0.4);

  & > a {
    font-weight: 400;
    padding: 0.3em 1em;
    white-space: nowrap;
    text-decoration: none !important;
    
    &:hover {
      background-color: var(--ked-gray);
    }
  }
`;

const OptionRail = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: space-around;
  border-top: 2px solid var(--ked-lite-gray);

  @media (min-width: 1100px) {
    padding: 0;
    width: auto;
    border: none;

    & > *  + * {
      margin-left: 1.5em ;
    }
  }

`;


const Naviagtion = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [openMobileMenu, toggleMobileMenu] = useState(false);

  const hideMobileMenu = event => {
    if (event.target.matches(".nav-link") || event.target.matches("a.inner-link")) {
      setTimeout(() => toggleMobileMenu(false), 300);
    }
  };

  return (
    <Wrapper open={openMobileMenu}>
      <Link to="/">
        <Logo src={kedLogoSvg} alt="Katalyst Education Logo" />
      </Link>

      <Rail className="buttons-container --rail-end --v-center" onClick={hideMobileMenu}>
        <DropContainer>
          <DropButton data-bui-dropdown-target="submenu" active={pathname === "/"}>
            <span>{t("navigation.about")}</span>
            <svg viewBox="0 0 20 20">
              <polygon points="14,11 14,0 6,0 6,11 1.133,11 10,19.867 18.866,11 " />
            </svg>
          </DropButton>
          <DropList name="submenu" autoHide>
            <Link className="inner-link" to="/">{t("navigation.general")}</Link>
            <Link className="inner-link" to="/#achievements">{t("navigation.achievements")}</Link>
            <Link className="inner-link" to="/#projects">{t("navigation.projects")}</Link>
            <Link className="inner-link" to="/#mission">{t("navigation.mission")}</Link>
            <Link className="inner-link" to="/#philosophy">{t("navigation.philosophy")}</Link>
            <Link className="inner-link" to="/#team">{t("navigation.team")}</Link>
            <Link className="inner-link" to="/acknowledgments">{t("navigation.acknowledgments")}</Link>
            <Link className="inner-link" to="/board">{t("navigation.board")}</Link>
            <Link className="inner-link" to="/documents">{t("navigation.documents")}</Link>
          </DropList>
        </DropContainer>
        <NavigationLink className="nav-link" to="/collaboration">{t("navigation.collaboration")}</NavigationLink>
        <NavigationLink className="nav-link" to="/media">{t("navigation.media")}</NavigationLink>
        <NavigationLink className="nav-link" to="/contact">{t("navigation.contact")}</NavigationLink>
        <NavigationLink className="nav-link" to="/donate">{t("navigation.donate")}</NavigationLink>
        <OptionRail>
          <OppButton title="Organizacja pożytku publicznego" />
          <LanguaneToggle />
        </OptionRail>
      </Rail>

      <Hamburger
        className="hamburger"
        open={openMobileMenu}
        color="var(--ked-blue)"
        onClick={() => toggleMobileMenu(!openMobileMenu)} />

    </Wrapper>
  );
}

Naviagtion.displayName = "Naviagtion";
export default Naviagtion;

