// Based on https://github.com/jonsuh/hamburgers by @jonsuh

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Hamburger = styled.span`
  border: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  overflow: visible;
  padding: 15px 15px;
  text-transform: none;
  display: flex;
  box-sizing: border-box;
  transition-duration: 0.3s;
  background-color: transparent;
  transition-timing-function: linear;
  transition-property: opacity, filter;

  ${({height, space}) => `
    line-height: ${(height * 3 + space * 2) * 2 }px;
  `};

  &:focus {
    outline: none;
  }
`;

const Box = styled.span`
  ${({width, height, space}) => `
    width: ${width}px;
    height: ${height * 3 + space * 2}px;
  `};
  position: relative;
  display: inline-block;
`;

const Bars = styled.span`
  top: 50%;
  display: block;
  margin-top: -2px;

  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${({open}) => open && `
    transition-delay: 0.12s;
    transform: rotate(225deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  `}

  &, &::before, &::after {
    position: absolute;
    border-radius: 4px;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease;

    ${({color, width, height}) => `
      width: ${width}px;
      height: ${height}px;
      background-color: ${color};
    `};
  }

  &::before, &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -${({space, height}) => space + height }px;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;

    ${({open}) => open && `
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    `}
  }

  &::after {
    bottom: -${({space, height}) => space + height }px;
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    ${({open}) => open && `
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    `}
  }
`;

export default class HamburgerButton extends React.PureComponent {

  static propTypes = {
    color: PropTypes.string,
    space: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    open: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    space: 6,
    width: 40,
    height: 4,
    color: "#666"
  };

  render() {
    const {open, width, height, space, color, ...props} = this.props
    return (
      <Hamburger {...props} height={height} space={space}>
        <Box width={width} height={height} space={space}>
          <Bars open={open} color={color} width={width} height={height} space={space}/>
        </Box>
      </Hamburger>
    )
  }
}
