export function sortByOrder(a, b) {
  return parseInt(a.order) - parseInt(b.order);
}

export function watchForServerErrors(response) {
  if (response.status === "error") {
    throw new Error(response.message);
  }
  return response;
}

export function formatPageContent(data) {
  return data
    .map((page) => ({
      id: page.id,
      slug: page.slug,
      title: page.title.rendered,
      content: page.content.rendered,
      excerpt: page.excerpt.rendered,

      // Basic ext.
      url: page.acf?.url,
      order: page.acf?.order,
      cover: page.acf?.cover,

      // Media ext.
      flyer: page.acf?.flyer,
      images: page.acf?.images,
      presspack: page.acf?.presspack,

      // Project ext.
      uid: page.acf?.project_uid,
      project_type: page.acf?.project_type,

      // Contact ext.
      subjects: page.acf?.subjects,

      // Donate ext.
      transferData: page.acf?.transfer_data,
      transferInfo: page.acf?.transfer_info,
    }))
    .toSorted(sortByOrder);
}

export function formatContact(data) {
  return data
    .map((member) => ({
      id: member.id,
      name: member.title.rendered,
      email: member.acf?.email,
      order: member.acf?.order,
      linkedin: member.acf?.linkedin_url,
      job_title_pl: member.acf?.job_title_pl,
      job_title_en: member.acf?.job_title_en,
    }))
    .toSorted(sortByOrder);
}

export function formatBoardMembers(data) {
  return data
    .map((member) => ({
      id: member.id,
      order: member.acf.order,
      name: member.title.rendered,
      job_title_pl: member.acf.job_title_pl,
      description_pl: member.acf.description_pl,
      job_title_en: member.acf.job_title_en,
      description_en: member.acf.description_en,
    }))
    .toSorted(sortByOrder);
}

export function formatDocuments(data) {
  return data
    .map((doc) => ({
      id: doc.id,
      order: doc.acf.order,
      url: doc.acf.document,
      title_pl: doc.acf.title_pl,
      title_en: doc.acf.title_en,
    }))
    .toSorted(sortByOrder);
}

export function formatPartners(data) {
  return sortPartners(
    data
      .map((partner) => ({
        id: partner.id,
        logo: partner.acf.logo,
        order: partner.acf.order,
        website: partner.acf.website,
        type: partner.acf.support_type.slug,
      }))
      .toSorted(sortByOrder)
  );
}

// Sort partners by custom order.
function sortPartners(data) {
  return data.reduce((acc, item) => {
    const type = item.type || "other";

    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push(item);
    return acc;
  }, {});
}
