import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text } from "@ludekarts/base-ui";
import Footer from "components/footer";
import { useTranslation } from "react-i18next";


import { LShape, TShape, SquareShape, PlusShape, RectShape } from "components/tetris";

// Layout.
import { Container, Stack, Space, Rail } from "@ludekarts/base-ui/layout";

const Tetris = styled.div`
  position: relative;
  width: 100%;

  & > .tetris {
    position: absolute;
    bottom: 0;
  }

  & > .shape-1 {
    left: 0%;
  }
 
  & > .shape-2 {
    left: 100px;
  }
 
  & > .shape-3 {
    left: 230px;
  }
 
  & > .shape-4 {
    left: 262px;
    bottom: 32px;
  }
  
  & > .shape-5 {
    left: 30%;
  }
 
  & > .shape-6 {
    left: 45%;
  }

  & > .shape-7 {
    right: 32%;
  }

  & > .shape-8 {
    right: 20%;
  }
  
  & > .shape-9 {
    right: 0;
  }
`;

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Container offsetTop="16em">
      <Stack className="--stack-center" gap="4em">
        <Text size="15em" weight="800" color="var(--ked-blue)">404</Text>
        <Text size="2em" weight="800" color="var(--ked-text-gray)" transform="uppercase">{t("404.message")}</Text>
      </Stack>
      <Rail className="--rail-center --space-2x">
        <Link to="/">{t("404.link")}</Link>
      </Rail>
      <Space space="3em" />
      <Footer>
        <Tetris>
          <LShape className="tetris shape-1" size="4em" />
          <TShape className="tetris shape-2" size="6em" rotate="180" color="var(--ked-yellow)" />
          <SquareShape className="tetris shape-3" size="2em" />
          <SquareShape className="tetris shape-4" size="2em" />
          <RectShape className="tetris shape-5" size="4em" />
          <LShape className="tetris shape-6" size="4em" rotate="90" color="var(--ked-lite-blue)" />
          <SquareShape className="tetris shape-7" size="2em" />
          <TShape className="tetris shape-8" size="5.5em" rotate="180" color="var(--ked-violet)" />
          <PlusShape className="tetris shape-9" size="6em" />
        </Tetris>
      </Footer>
    </Container>

  );
}

NotFound.displayName = "NotFound";
export default NotFound;
