import React from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Google tag manager.
// import TagManager from "react-gtm-module";

// Styles.
import { GlobalStyles } from "@ludekarts/base-ui";
import KedStyles from "components/global-styles";

// Hooks.
import useUrlLanguage from "hooks/use-url-language";

// Components.
import Naviagtion from "components/navigation";

// Config.
import config from "config";

// Includeg graphics in build porecess.
import "assets/images/icons.svg";
import "assets/images/ked-email-banner.jpg";

// Pages.
import Media from "../media";
import Board from "../board";
import NotFound from "../404";
import Donate from "../donate";
import Thanks from "../thanks";
import Landing from "../landing";
import Contact from "../contact";
import Project from "../project";
import Mission from "../mission";
import Documents from "../documents";
import Philosophy from "../philosophy";
import PrivacyPolicy from "../privacy";
import Collaboration from "../collaboration";
import Acknowledgments from "../acknowledgments";

// if (ENVIRONMENT !== "production") {
//   TagManager.initialize({
//     gtmId: config.gtmId,
//   });
// }

export default hot(module)(function KatalystEducation() {
  useUrlLanguage();
  return (
    <Router>
      <GlobalStyles>
        <Naviagtion />
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/collaboration" exact>
            <Collaboration />
          </Route>
          <Route path="/donate" exact>
            <Donate />
          </Route>
          <Route path="/media" exact>
            <Media />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/board" exact>
            <Board />
          </Route>
          <Route path="/philosophy" exact>
            <Philosophy />
          </Route>
          <Route path="/mission" exact>
            <Mission />
          </Route>
          <Route path="/acknowledgments" exact>
            <Acknowledgments />
          </Route>
          <Route path="/documents" exact>
            <Documents />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/project/:slug" exact>
            <Project />
          </Route>
          <Route path="/thanks" exact>
            <Thanks />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </GlobalStyles>
      <KedStyles />
    </Router>
  );
});
