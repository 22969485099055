import React from "react";
import Html from "components/html";
import api, { useApi } from "api";
import styled from "styled-components";
import FadeIn from "components/fade-in";
import Footer from "components/footer";
import Slider from "components/slider";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "components/i18n";
import { Container, Stack, Space } from "@ludekarts/base-ui/layout";

const Wrapper = styled.div`
  & > * + * {
    margin-top: 3em;
  }

  --main-color: black; //--ked-lite-blue

  & .ked-html {
    line-height: 1.5em;
    padding: 3em 2em 2em;
    background-color: var(--main-color);

    & .content-rail > * {
      gap: 2em;
      display: flex;
      flex-wrap: wrap;
    }

    & h2:first-of-type {
      left: 0;
      right: 0;
      top: -3em;
      padding: 0.5em 1em;
      position: absolute;
      display: inline-flex;
      background-color: white;
      border: 4px solid var(--main-color);

      @media (min-width: 540px) {
        top: -1.3em;
        right: 1.5em;
        left: unset;
      }
    }

    & h3 {
      color: white;
      margin: 1em 0 0.5em;
    }

    & ul {
      margin: 0.5em 0;
      list-style: disc;
      padding-left: 1.5em;
    }
  }

  & .testimonials {
    text-align: center;

    & strong {
      font-size: 4em;
      color: var(--main-color);
    }

    & .emphasise {
      margin: 0.4em;
      font-size: 2.2rem;
      font-weight: bold;
      color: var(--main-color);
    }

    & blockquote {
      text-align: left;
      margin-top: 3.5em;

      & > p:last-child {
        text-align: right;
        font-size: 0.8em;

        & strong {
          font-size: 1.4em;
          display: block;
          margin-top: 1em;
        }
      }

      &::before {
        top: 0;
        left: -0.1em;
        content: "🙸";
        opacity: 0.25;
        font-size: 11em;
        position: absolute;
        font-style: normal;
        color: var(--main-color);
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 3em;
  }

  @media (min-width: 1100px) {
    flex-direction: row;

    &:last-child {
      flex-direction: row-reverse;
    }

    & > * + * {
      margin-top: 0;
      margin-left: 3em;
    }
  }
`;

const Header = styled.h1`
  font-size: 10vw;
  @media (max-width: 540px) {
    margin-bottom: 2.5rem;
  }
  @media (min-width: 390px) {
    font-size: 2.5em;
  }
`;

const Section = styled.div`
  position: relative;
  margin-bottom: auto;

  &.yellow {
    --main-color: var(--ked-orange);
  }

  &.blue {
    --main-color: var(--ked-lite-blue);
  }
`;

const KpiTop = styled.div`
  padding: 1em;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  --main-color: var(--pie-color);

  & .slider {
    margin-top: 3em;
  }

  @media (min-width: 920px) {
    margin-top: 5em;
  }

  @media (min-width: 1100px) {
    width: 35%;
    margin-top: 0;
    flex-shrink: 0;
  }

  & h3 {
    width: 100%;
    color: white;
    font-size: 1.5em;
    padding: 0.5em 1em;
    display: inline-flex;
    justify-content: center;
    background-color: var(--main-color);

    @media (min-width: 920px) {
      top: 0;
      right: 0;
      min-width: 18rem;
      position: absolute;

      &::before {
        content: "";
        bottom: 100%;
        right: 0;
        width: 2.5em;
        height: 2.5em;
        position: absolute;
        background-color: var(--main-color);
      }
    }
  }
`;

const KpiBottom = styled.div`
  padding: 1em;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  --main-color: var(--cm-color);

  @media (min-width: 920px) {
    margin-top: 5em;
  }

  @media (min-width: 1100px) {
    width: 35%;
    flex-shrink: 0;
    margin-left: 0;
    margin-top: 4em;
    margin-right: 3em;
  }

  & > div:last-child {
    margin-bottom: auto;
  }

  & h3 {
    color: white;
    font-size: 1.5em;
    padding: 0.5em 1em;
    display: inline-flex;
    justify-content: center;
    background: var(--main-color);

    @media (min-width: 920px) {
      top: 0;
      left: 0;
      min-width: 18rem;
      position: absolute;

      &::before {
        content: "";
        bottom: 100%;
        left: 0;
        width: 2.5em;
        height: 2.5em;
        position: absolute;
        background-color: var(--main-color);
      }
    }
  }
`;

const Collaboration = () => {
  const { t } = useTranslation();
  const lang = getCurrentLanguage();
  const [content] = useApi(api.getCollaborationContent, lang);

  return (
    <>
      <FadeIn trigger={!!content}>
        {content && (
          <>
            <Container offsetTop="6rem">
              <Stack gap="5em">
                <Header>{t("colaboration.header")}</Header>
                <Wrapper>
                  <Row>
                    <Section className="lift-panel ked-html yellow">
                      <h2>{content.schools.title}</h2>
                      <Html
                        as="section"
                        textColor="white"
                        akcentColor="var(--ked-blue)"
                      >
                        {content.schools.content}
                      </Html>
                    </Section>
                    <KpiTop className="pi-stacja">
                      <h3>{t("colaboration.pie-header")}</h3>
                      <Slider.Container
                        className="slider testimonials"
                        autoslide={3500}
                        minH="18rem"
                      >
                        {content.testimonialsPie.map((testimonial) => (
                          <Slider.Card key={testimonial.id}>
                            <Html as="section">{testimonial.content}</Html>
                          </Slider.Card>
                        ))}
                      </Slider.Container>
                    </KpiTop>
                  </Row>
                  <Row>
                    <Section className="lift-panel ked-html blue">
                      <h2>{content.business.title}</h2>
                      <Html
                        as="section"
                        textColor="white"
                        akcentColor="var(--ked-blue)"
                      >
                        {content.business.content}
                      </Html>
                    </Section>
                    <KpiBottom className="career-map">
                      <h3>{t("colaboration.cm-header")}</h3>
                      <Slider.Container
                        autoslide={3500}
                        minH="18rem"
                        className="testimonials"
                      >
                        {content.testimonialsCm.map((testimonial) => (
                          <Slider.Card key={testimonial.title}>
                            <Html as="section">{testimonial.content}</Html>
                          </Slider.Card>
                        ))}
                      </Slider.Container>
                    </KpiBottom>
                  </Row>
                </Wrapper>
              </Stack>
              <Space space="1em" />
            </Container>
            <Footer />
          </>
        )}
      </FadeIn>
    </>
  );
};

Collaboration.displayName = "Collaboration";
export default Collaboration;
