import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import * as baseUi from "@ludekarts/base-ui";

// Api.
import api, { useApi } from "api";
import getReCaptchaToken from "helpers/recaptcha";

// I18n.
import { getCurrentLanguage } from "components/i18n";
import { useTranslation } from "react-i18next";

// Layout.
import { Container, Stack, Space } from "@ludekarts/base-ui/layout";

// Components.
import Rodo from "components/rodo";
import Footer from "components/footer";
import FadeIn from "components/fade-in";
import Html from "components/html";
import Prompter from "components/prompter";
import MemberCard from "components/cards/member";
import { Form, InteractiveInput, SubmitButton } from "components/form";

const Wrapper = styled.div`
  gap: 1em;
  display: grid;
  padding-bottom: 3em;
  grid-template-columns: 1fr;

  @media (min-width: 1100px) {
    gap: 3em;
    grid-template-columns: 23em 1fr;
  }
`;

const Bricks = styled.div`
  gap: 1em;
  display: grid;
  min-height: 29em;
  grid-template-columns: 1fr;

  @media (min-width: 750px) {
    margin-top: 2em;
  }

  @media (min-width: 750px) {
    gap: 2em 3em;
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
  }
`;

const colors = [
  "var(--ked-blue)",
  "var(--ked-green)",
  "var(--ked-violet)",
  "var(--ked-lite-blue)",
  "var(--ked-orange)",
  "var(--ked-red)",
  "var(--ked-yellow)",
];

const Contact = () => {
  const ctForm = useRef();
  const { t } = useTranslation();
  const formStatus = useRef("idle");
  const lang = getCurrentLanguage();
  const [prompterContent, setPrompter] = useState();
  const [contact] = useApi(api.getContactContent, lang);

  const submitForm = async (event) => {
    event.preventDefault();
    const userData = baseUi.Form.formInputsToObject(event.target);

    if (formStatus.current === "idle") {
      formStatus.current = "pending";
      try {
        const rcToken = await getReCaptchaToken();
        await api.sendContactForm({ ...userData, rcToken });
        formStatus.current = "idle";
        setPrompter({ info: t("contact.message-success") });
        ctForm.current.reset();
      } catch (error) {
        console.error(error);
        formStatus.current = "error";
        setPrompter({ error: t("contact.message-error") });
      }
    }
  };

  return (
    <>
      <Helmet>
        <script src={api.connectReCaptcha()}></script>
      </Helmet>
      <FadeIn trigger={!!contact}>
        {contact && (
          <>
            <Container offsetTop="6rem">
              <h1>{contact.title}</h1>
              <Wrapper>
                <Html as="section" akcentColor="var(--ked-green)">
                  {contact.content}
                </Html>
                <Bricks>
                  {contact.contacts.map((member, index) => (
                    <MemberCard
                      lang={lang}
                      key={member.id}
                      member={member}
                      color={colors[index]}
                    />
                  ))}
                </Bricks>
              </Wrapper>

              <Stack gap="2em">
                <h2>{t("contact.headline")}</h2>
                <Form ref={ctForm} className="contact" onSubmit={submitForm}>
                  <Stack gap="1.8em">
                    <InteractiveInput data-label={t("form.first-name")}>
                      <input
                        required
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={t("form.first-name")}
                      />
                    </InteractiveInput>

                    <InteractiveInput data-label={t("form.last-name")}>
                      <input
                        required
                        type="text"
                        name="lastName"
                        placeholder={t("form.last-name")}
                      />
                    </InteractiveInput>

                    <InteractiveInput data-label={t("form.email")}>
                      <input
                        required
                        type="email"
                        name="email"
                        placeholder={t("form.email")}
                      />
                    </InteractiveInput>

                    <Rodo color="var(--ked-blue)" />

                    <SubmitButton
                      type="submit"
                      className="lift-panel submit-dektop"
                    >
                      {t("contact.submit-button")}
                    </SubmitButton>
                  </Stack>

                  <Stack gap="1.8em">
                    <InteractiveInput data-label={t("contact.subject")}>
                      <select name="subject">
                        {contact.subjects.split("\n").map((subject) => (
                          <option key={subject} value={subject}>
                            {subject}
                          </option>
                        ))}
                      </select>
                    </InteractiveInput>

                    <InteractiveInput data-label={t("contact.content")}>
                      <textarea
                        name="message"
                        placeholder={t("contact.content")}
                        rows="12"
                      ></textarea>
                    </InteractiveInput>

                    <SubmitButton
                      type="submit"
                      className="lift-panel submit-mobile"
                    >
                      {t("contact.submit-button")}
                    </SubmitButton>
                  </Stack>
                </Form>
              </Stack>
              <Space space="2em" />
            </Container>
            <Footer />
          </>
        )}
      </FadeIn>
      <Prompter
        source={prompterContent}
        onClose={() => {
          setPrompter(undefined);
          formStatus.current = "idle";
        }}
      />
    </>
  );
};

Contact.displayName = "Contact";
export default Contact;
