import React, { Children, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Card = styled.div` 
  ${({ active }) => active
    ? `
      opacity: 1;
      visibility: visible;
      pointer-events: all;  
      transform: translateY(0);
      transition: transform .6s ease, opacity .6s ease, visibility 0s ease;
    `
    : `      
      opacity: 0;
      visibility: hidden;
      pointer-events: none;  
      transform: translateY(-2em);
      transition: transform .6s ease, opacity .6s ease, visibility 0s ease .6s;
    `
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;  
  position: relative;
  align-items: center;
  justify-content: center;
  
  ${({ minH }) => !minH ? `` : `
    min-height: ${minH};
  `}
    
  & > * {
    position: absolute;
  }

  & > button {
    position: absolute;
    
    &:first-child {
      left: 0;
      z-index: 1;
    }
    
    &:last-child {
      right: 0;
      z-index: 1;
    }
  }
`;

const Container = props => {
  const { children, looper, autoslide, minH, ...rest } = props;
  const totalChildrenCount = children.length;
  const [currentActive, setCurrentActive] = useState(0);

  const nextCard = () => {
    setCurrentActive(currentActive => (currentActive + 1) % totalChildrenCount);
  };

  const prevCard = () => {
    setCurrentActive(currentActive => (currentActive - 1 + totalChildrenCount) % totalChildrenCount);
  };

  useEffect(() => {
    let timer;

    if (typeof autoslide === "number") {
      timer = setInterval(nextCard, autoslide);
    }

    return () => {
      timer && clearInterval(timer);
    }
  }, [autoslide]);

  return (
    <Wrapper minH={minH} {...rest}>
      {
        autoslide || (!looper && currentActive === 0) ? null :
          <button onClick={prevCard}>&lt;</button>
      }
      {
        Children.map(children, (child, index) =>
          currentActive === index
            ? React.cloneElement(child, { className: "active", active: true })
            : React.cloneElement(child)
        )
      }
      {
        autoslide || (!looper && currentActive === totalChildrenCount - 1) ? null :
          <button onClick={nextCard}>&gt;</button>
      }
    </Wrapper>
  );
}

Container.displayName = "SwipeContainer";
Container.propTypes = {
  looper: PropTypes.bool,
  autoslide: PropTypes.number,
};

Container.defaultProps = {
  looper: false,
};

export default { Container, Card };