import React from "react";
import Html from "components/html";
import PropTypes from "prop-types";
import { Wrapper } from "./styled";
import { useTranslation } from "react-i18next";
import ArrowButton from "components/arrow-button";
import AspcectRatio from "components/aspect-ratio";

const ProjectCard = (props) => {
  const { project, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Wrapper
      {...rest}
      className="lift-card"
      projectType={getProjectType(project.project_type, t)}
    >
      <AspcectRatio ratio="square">
        <a href={project.url} target="_blank">
          <img src={project.cover} />
          <Html className="content">{project.excerpt}</Html>
          <ArrowButton className="arrow-button" color="var(--ked-yellow)" />
        </a>
      </AspcectRatio>
    </Wrapper>
  );
};

ProjectCard.displayName = "ProjectCard";
ProjectCard.propTypes = {
  project: PropTypes.object,
};

ProjectCard.defaultProps = {};

export default ProjectCard;

// ---- Helpers ----------------

function getProjectType(type, t) {
  return type === "project"
    ? undefined
    : type === "incubated"
    ? t("project-types.incubated")
    : type === "partner"
    ? t("project-types.partner")
    : undefined;
}
