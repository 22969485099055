import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
 
  /* GLobal Variables */ 

  :root {
    
    /* Colors */

    --ked-red:         #D92828;
    --ked-blue:        #004070;
    --ked-green:       #20AC35;
    --ked-orange:      #E6B220;
    --ked-yellow:      #EFE13A;
    --ked-violet:      #DF8BD9;
    --ked-lite-blue:   #4492d5;
    
    --ked-black:       #1c1c1c;
    --ked-gray:        #DDDDDD;
    --ked-lite-gray:   #EEEEEE;
    --ked-text:        #232323;
    --ked-text-gray:   #526572;
    --ked-text-light:  #666666;

    /* Product Colors */

    --pie-color: #80B742;
    --cm-color:  #213F80;
    --osp-color: #F4D10F;
    --opp-color: #e96b04;

  }

  html, body {
    scroll-behavior: smooth;
  }
    
  /* Remove default padding */
  ul, ol {
    padding: 0;
    list-style: none;
  }

  /* Remove default margin */
  body, h1, h2, h3, h4, h5, p, ul, ol,
  li, figure, figcaption, blockquote, dl, dd {
    margin: 0;
  }


  /* Set core body defaults */
  body {     
    font-size: 1em;
    overflow-y: scroll;
    line-height: 1.4em;     
    text-rendering: optimizeSpeed;
    font-family: "Poppins", sans-serif;  
  }


  /* Default Typography */

  h1 {
    font-weight: 800;
    font-size: 2.5em;
    line-height: 1.3em;
    color: var(--ked-blue);
    text-transform: uppercase;
  }

  h2 {
    font-weight: 600;
    font-size: 1.8em;
    line-height: 1.3em;
    color: var(--ked-blue);
  }

  h3 {
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.4em;
    color: var(--ked-blue);
  }

  h4 {
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.5em;
    color: var(--ked-blue);
  }

  h5 {
    font-weight: 600;
    font-size: 0.9em;
    line-height: 1.2em;
  }

  strong {
    font-weight: 600;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  a, button {
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .3s ease;
  }

  a:not([class]) {
    color: var(--ked-blue);
    &:hover {
      text-decoration: underline;
    }
  }

  /* UTILS */

  .lift-card {
    box-shadow:  0px 2px 10px -4px rgba(0,0,0,0.5), 0px 5px 5px -2px rgba(0, 0, 0, 0);
    transition: transform .3s ease, box-shadow .3s ease;
     
    &:hover {
      transform: translateY(-0.5em);
      box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5), 0px 10px 48px -25px rgba(0, 0, 0, 0.7);
    }
  }
 
  
  .lift-panel {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    transition: transform .3s ease, box-shadow .3s ease;

    &:hover {      
      transform: translateY(-0.5em);
      box-shadow: 0px 19px 19px -13px rgba(0,0,0,0.4);
    }
  }    
`;
