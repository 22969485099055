import styled from "styled-components";
import { Container } from "@ludekarts/base-ui/layout";

export const Wrapper = styled(Container)`
  overflow-x: hidden;
`;

export const Header = styled.h1`
  font-size: 10vw;
  text-transform: none;
  scroll-margin-top: 4rem;

  @media (min-width: 460px) {
    font-size: 2.5em;
  }
`;

export const Subline = styled.strong`
  color: white;
  padding: 1em;
  font-size: 1.2em;
  text-align: center;
  margin: 3em 0 !important;
  background-color: var(--ked-black);
`;
