import React from "react";
import PropTypes from "prop-types";
import Html from "components/html";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Stack } from "@ludekarts/base-ui/layout";
import AspectRatio from "components/aspect-ratio";

const ProductCardWrapper = styled(Link)`
  width: 100%;
  max-width: 19em;

  color: white;
  text-align: center;
  aspect-ratio: 1 / 1;

  gap: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & > * {
    pointer-events: none;
  }

  & p,
  & img {
    padding: 1em;
  }

  @media (max-width: 790px) {
    aspect-ratio: unset;
    max-width: unset;
    padding: 2em;
  }

  /* Colors */

  &:nth-of-type(1) {
    background-color: var(--pie-color);
  }

  &:nth-of-type(2) {
    background-color: var(--cm-color);
  }

  &:nth-of-type(3) {
    background-color: var(--osp-color);
  }
`;

const ProductCard = (props) => {
  const { product } = props;
  return (
    <ProductCardWrapper
      className="lift-card"
      aria-label={product.title}
      to={`/project/${product.uid}`}
    >
      <img src={product.cover} />
      <Html>{product.excerpt}</Html>
    </ProductCardWrapper>
  );
};

ProductCard.displayName = "ProductCard";
ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

ProductCard.defaultProps = {};

export default ProductCard;
