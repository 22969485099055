import config from "config";

export default function getReCaptchaToken() {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function () {
      grecaptcha.execute(config.recaptchaKey, { action: "submit" })
        .then(resolve)
        .catch(reject);
    })
  })
}
