import React from "react";
import Icon from "components/icon";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text } from "@ludekarts/base-ui";
import { useTranslation, getI18n } from "react-i18next";
import { Rail, Stack, Grid } from "@ludekarts/base-ui/layout";

// Only for bundler to acknowledge this images.
import "assets/images/SDG_04_PL.svg";
import "assets/images/SDG_08_PL.svg";
import "assets/images/SDG_10_PL.svg";
import "assets/images/SDG_04_EN.svg";
import "assets/images/SDG_08_EN.svg";
import "assets/images/SDG_10_EN.svg";

const Wrapper = styled.footer`
  padding: 2em;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: white;
  color: var(--ked-text-gray);
  border-top: 2px solid var(--ked-blue);

  & > div:first-child {
    gap: 5rem;
    width: 100%;
    display: grid;
    max-width: 78rem;
    padding: 0px 1.5em;
    grid-template-columns: 26rem 1fr;

    @media (max-width: 1250px) {
      padding: 0;
    }

    @media (max-width: 1100px) {
      gap: 2rem;
      grid-template-columns: 1fr;
    }
  }

  & div .links {
    gap: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1100px) {
      grid-row: 1/2;
    }

    @media (max-width: 560px) {
      gap-x: 5rem;
      display: flex;
      flex-wrap: wrap;
    }
  }

  & .iconlink {
    gap: 1rem;
    display: flex;
    color: var(--ked-blue);
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  bottom: 102%;
  display: flex;
  position: absolute;
`;

const Footer = (props) => {
  const { children, ...rest } = props;
  const { t } = useTranslation();
  const lang = getI18n().language.toLocaleUpperCase();
  return (
    <Wrapper>
      {children && <Content {...rest}>{children}</Content>}
      <div>
        <Stack gap="0.3rem" className="sdg">
          <Text size="1.2em" color="var(--ked-black)">
            {t("footer.copyright", { year: new Date().getFullYear() })}
          </Text>

          <Stack gap="0.8rem">
            <Text>{t("footer.sdg")}</Text>
            <Rail gap="1rem">
              <img
                src={`./assets/images/SDG_04_${lang}.svg`}
                alt="KED Logo"
                width="80px"
              />
              <img
                src={`./assets/images/SDG_08_${lang}.svg`}
                alt="KED Logo"
                width="80px"
              />
              <img
                src={`./assets/images/SDG_10_${lang}.svg`}
                alt="KED Logo"
                width="80px"
              />
            </Rail>
          </Stack>
        </Stack>

        <div className="links">
          <Stack gap="0.3rem">
            <Text color="var(--ked-black)" weight="bold">
              {t("footer.about")}
            </Text>
            <Link to="/mission">{t("footer.mission")}</Link>
            <a
              href="https://mapakarier.org"
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>Mapa Karier</span>
            </a>
            <a
              href="https://pistacja.tv"
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>Pi-stacja</span>
            </a>
            <Link to="/privacy-policy">{t("footer.privacy")}</Link>
          </Stack>
          <Stack gap="0.3rem">
            <Text color="var(--ked-black)" weight="bold">
              {t("footer.cooperation")}
            </Text>
            <Link to="/acknowledgments">
              <span>{t("footer.partners")}</span>
            </Link>
            <a
              href="https://sklep.katalysteducation.org/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>{t("footer.store")}</span>
            </a>
            <Link to="/donate">
              <span>{t("footer.donate")}</span>
            </Link>
          </Stack>
          <Stack gap="0.3rem">
            <Text color="var(--ked-black)" weight="bold">
              {t("footer.contact")}
            </Text>
            <Link to="/media">
              <span>{t("footer.media")}</span>
            </Link>
            <a
              href="https://www.linkedin.com/company/katalysteducation/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <span>LinkedIn</span>
            </a>
            <a
              href="https://www.facebook.com/PistacjaMatematyka/"
              rel="noreferrer noopener"
              className="iconlink"
              target="_blank"
            >
              <span>Facebook&nbsp;</span>
              <Icon name="pie" size="1em" color="currentColor" />
            </a>
            <a
              href="https://www.facebook.com/MapaKarier/"
              rel="noreferrer noopener"
              className="iconlink"
              target="_blank"
            >
              <span>Facebook&nbsp;</span>
              <Icon name="cm" size="1em" color="currentColor" />
            </a>
            <a
              href="https://www.instagram.com/Mapa.Karier/"
              rel="noreferrer noopener"
              className="iconlink"
              target="_blank"
            >
              <span>Instagram</span>
              <Icon name="cm" size="1em" color="currentColor" />
            </a>
          </Stack>
        </div>
      </div>
    </Wrapper>
  );
};

Footer.displayName = "Footer";
Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
